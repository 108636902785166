<template>
  <div :data-theme="themeName" class="pc-toggle">
    <input
      :id="id"
      class="pc-toggle-input absolute -left-9999"
      type="checkbox"
      :value="value"
      :checked="isChecked"
      :disabled="disabled"
      @change="onCheckboxChange"
    />
    <label
      :for="id"
      :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
      class="pc-toggle-outer"
    >
      <span class="mr-2 pc-toggle-label">
        {{ label }}
      </span>
      <div class="pc-toggle-inner">
        <div class="pc-toggle-dot" :class="{ disabled }">
          <pc-icon name="check" />
        </div>
      </div>
    </label>

    <p v-if="validationMessage" class="pc-toggle-error-message">
      <pc-icon class="mr-1 w-5 h-5" name="error" />
      {{ validationMessage }}
    </p>
  </div>
</template>

<script>
import PcIcon from '../Icon'
import checkbox from '../../mixins/checkbox'
import theme from '../../mixins/theme'

export default {
  name: 'PcToggle',
  components: {
    PcIcon
  },
  mixins: [checkbox, theme]
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';
.pc-toggle[data-theme='default'] {
  .pc-toggle-inner {
    @apply w-12
    h-6
    rounded-xl
    bg-blue-10
    transition-all
    duration-200
    ease-linear;
    padding: 0.125rem;

    &:hover {
      @apply bg-blue-20;
    }
    &:active {
      @apply bg-grey-40;
    }
  }
  .pc-toggle-outer {
    @apply flex items-center;
  }

  .pc-toggle-dot {
    @apply bg-white text-transparent
    w-5
    h-5
    rounded-full
    shadow-dropdown
    transition-all
    duration-300
    ease-linear;

    &.disabled {
      @apply bg-grey-45;
    }
  }

  .pc-toggle-input:checked + .pc-toggle-outer .pc-toggle-inner {
    @apply bg-blue-100;
    @apply justify-end;
  }

  .pc-toggle-input:checked + .pc-toggle-outer .pc-toggle-dot {
    @apply transform translate-x-6;
  }

  .pc-toggle-input:focus + .pc-toggle-outer .pc-toggle-inner {
    @apply shadow-outline-button;
  }

  .pc-toggle-input:disabled + .pc-toggle-outer .pc-toggle-inner {
    @apply bg-grey-30;
  }

  .pc-toggle-error-message {
    @apply mt-1 text-sm text-red-100 flex;
  }
}

.pc-toggle[data-theme='icing'] {
  .pc-toggle-inner {
    @apply w-12 h-6 p-0.5
    rounded-radius-c
    bg-interactive-form
    transition-all
    duration-200
    ease-linear;

    &:hover {
      background-color: var(--dt-pc-color-interactive-form-mix-hover-01);
    }
    &:active {
      background-color: var(--dt-pc-color-interactive-form-mix-active-01);
    }
  }
  .pc-toggle-outer {
    @apply flex items-center;
  }

  .pc-toggle-dot {
    @apply bg-interactive-light text-transparent
    w-5 h-5 p-0.5
    rounded-full
    shadow-elevation-01
    transition-all
    duration-200
    ease-linear;

    &.disabled {
      background-color: var(--dt-color-content-light);
    }
  }

  .pc-toggle-input:checked + .pc-toggle-outer .pc-toggle-dot {
    @apply transform translate-x-6;
  }
  .pc-toggle-input:checked + .pc-toggle-outer .pc-toggle-inner {
    @apply justify-end;
  }

  .pc-toggle-input:not(:disabled):checked + .pc-toggle-outer .pc-toggle-inner {
    @apply bg-interactive-brand;

    &:hover {
      background-color: var(--dt-pc-color-iteractive-brand-mix-hover-01);
    }

    &:active {
      background-color: var(--dt-pc-color-iteractive-brand-mix-active-01);
    }
  }

  .pc-toggle-input:not(:disabled):checked + .pc-toggle-outer .pc-toggle-dot {
    @apply text-interactive-brand;
  }

  .pc-toggle-input:not(:disabled):focus + .pc-toggle-outer .pc-toggle-inner {
    @apply shadow-reactive-focus-01;
  }

  .pc-toggle-input:disabled + .pc-toggle-outer .pc-toggle-inner {
    @apply bg-disabled-01;
  }

  .pc-toggle-input:checked:disabled + .pc-toggle-outer .pc-toggle-dot {
    @apply text-disabled-01;
  }

  .pc-toggle-error-message {
    @apply mt-1 text-sm text-support-error flex items-center;
    @mixin body-s;
  }
}
</style>
