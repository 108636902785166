<template>
  <component
    :is="getTag"
    :href="href"
    :class="[
      { 'pc-one-list-item--compact': compact },
      { 'pc-one-list-item--divider': divider }
    ]"
    class="pc-one-list-item"
    v-on="$listeners"
  >
    <div v-if="$slots.leftContent" class="pc-one-list-item-content--left">
      <slot name="leftContent"></slot>
    </div>
    <div class="pc-one-list-item-content">
      <div>
        <slot></slot>
        <p v-if="$slots.secondaryText"><slot name="secondaryText"></slot></p>
      </div>
      <div v-if="$slots.rightContent" class="ml-auto pl-4">
        <slot name="rightContent"></slot>
      </div>
    </div>
  </component>
</template>

<script>
import LinkHelper from '../Link/LinkHelper.vue'
const contentTypes = ['icon', 'metaText', 'avatar', 'illustration', 'thumbnail']

export default {
  components: {
    LinkHelper
  },
  props: {
    href: {
      type: String,
      default: ''
    },
    divider: {
      type: Boolean,
      default: false
    },
    interactive: {
      type: Boolean,
      default: false
    },
    leftContentType: {
      type: String,
      default: 'icon',
      validate(value) {
        contentTypes.includes(value)
      }
    },
    rightContentType: {
      type: String,
      default: 'icon',
      validate(value) {
        contentTypes.includes(value)
      }
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getTag() {
      const hasListeners = Object.keys(this.$listeners).length === 0
      return this.href || hasListeners ? 'link-helper' : 'div'
    }
  }
}
</script>

<style scoped>
.pc-one-list-item {
  @apply flex items-center cursor-pointer w-full bg-container-default;

  &:focus {
    @apply shadow-outline-focus;
  }

  &:hover {
    background-color: var(--dt-pc-color-backgound-default-mix-hover-01);
  }

  &:active {
    background-color: var(--dt-pc-color-backgound-default-mix-active-01);
  }

  .pc-one-list-item-content {
    @apply flex flex-row justify-between w-full p-4;
  }

  &--compact .pc-one-list-item-content {
    @apply py-3;
  }

  .pc-one-list-item-content--left {
    @apply px-4;
  }

  .pc-one-list-item-content--left + .pc-one-list-item-content {
    @apply pl-0;
  }

  &--divider .pc-one-list-item-content {
    @apply flex-1 border-b border-color-default;
  }
}
</style>
