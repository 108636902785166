var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "21",
        height: "20",
        viewBox: "0 0 21 20",
        fill: "none",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_5205_32582)" } }, [
        _c("path", {
          attrs: {
            d: "M19.25 9.06248H4.6875L9.3875 4.36248L8.0625 3.03748L2.2125 8.89997C2.06737 9.04405 1.95219 9.21542 1.87359 9.40421C1.79498 9.593 1.75452 9.79548 1.75452 9.99998C1.75452 10.2045 1.79498 10.407 1.87359 10.5957C1.95219 10.7845 2.06737 10.9559 2.2125 11.1L8.0625 16.9625L9.3875 15.6375L4.6875 10.9375H19.25V9.06248Z",
            fill: "#F36805",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_5205_32582" } }, [
          _c("rect", {
            attrs: {
              width: "20",
              height: "20",
              fill: "white",
              transform: "translate(0.5)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }