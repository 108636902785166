var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.getTag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          staticClass: "pc-link-helper",
          attrs: { "active-class": _vm.activeClass },
        },
        "component",
        _vm.getProps,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }