var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pc-progress",
      class: [{ "pc-progress-brand": _vm.brand }],
      attrs: { "data-theme": _vm.themeName },
    },
    [
      _vm.label
        ? _c("div", { staticClass: "pc-progress-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "pc-progress-track" }, [
        _c("div", {
          staticClass: "pc-progress-bar",
          style: { width: _vm.percent + "%" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }