<template>
  <component
    :is="getTag"
    :data-theme="themeName"
    class="pc-btn"
    :class="[
      `pc-btn--${type}`,
      `pc-btn--${size}`,
      {
        'pc-btn--loading': loading,
        'pc-btn--disabled': disabled,
        'pc-btn--circle': circle
      }
    ]"
    :disabled="disabled"
    v-bind="getProps"
    v-on="$listeners"
  >
    <div class="pc-btn-inner">
      <pc-icon
        v-if="loading"
        name="loading"
        class="pc-btn-loader animate-spin absolute inset-0 m-auto"
      />
      <div
        class="flex items-center justify-center text-center"
        :class="{ invisible: loading }"
      >
        <span
          v-if="icon && iconPosition === 'leading'"
          class="pc-btn-icon--wrapper"
          :class="circle ? '' : 'mr-2'"
        >
          <pc-icon v-if="icon" class="pc-btn-icon" :name="icon"></pc-icon>
        </span>
        <slot></slot>
        <span
          v-if="icon && iconPosition === 'trailing'"
          class="pc-btn-icon--wrapper ml-2"
        >
          <pc-icon v-if="icon" class="pc-btn-icon" :name="icon"></pc-icon>
        </span>
      </div>
    </div>
  </component>
</template>

<script>
import theme from '../../mixins/theme'
import PcIcon from '../Icon'
import { ButtonSizesList, ButtonTypesList } from './constants'

export default {
  name: 'PcButton',
  components: {
    PcIcon
  },
  mixins: [theme],
  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return Object.prototype.hasOwnProperty.call(ButtonTypesList, value)
      }
    },
    size: {
      type: String,
      default: 'small',
      validator(value) {
        return Object.prototype.hasOwnProperty.call(ButtonSizesList, value)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'leading',
      validate(value) {
        return ['leading', 'trailing'].includes(value)
      }
    },
    circle: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExternalLink() {
      return this.href?.match(/^(http(s)?):\/\//)
    },
    getTag() {
      if (this.href) {
        return this.isExternalLink ? 'a' : 'router-link'
      }

      return 'button'
    },
    getProps() {
      if (this.href) {
        return this.isExternalLink
          ? { href: this.href, target: '_blank' }
          : { to: this.href }
      }
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';
.pc-btn[data-theme='default'] {
  @apply inline-block
    font-bold
    rounded-sm
    box-border
    border
    cursor-pointer
    relative;

  &:focus {
    @apply outline-none shadow-outline-button;
  }

  .pc-btn-icon--wrapper {
    @apply flex items-center w-4 h-4;
  }

  .pc-btn-icon {
    min-width: 100%;
    min-height: 100%;
  }

  &.pc-btn--primary:not([disabled]) {
    @apply text-white bg-blue-100 border-blue-100;

    &:hover {
      @apply bg-blue-200 border-blue-200;
    }

    &:active {
      @apply bg-blue-300 border-blue-300;
    }

    &.pc-btn--large {
      @apply text-white bg-orange-100 border-orange-100;

      &:hover {
        @apply bg-orange-100 border-orange-100;
      }

      &:active {
        @apply bg-orange-100 border-orange-100;
      }
    }
  }
  &.pc-btn--secondary:not([disabled]) {
    @apply text-blue-100 bg-blue-10 border-blue-10;

    &:hover {
      @apply bg-blue-20 border-blue-20;
    }

    &:active {
      @apply bg-grey-40 border-grey-40;
    }
  }
  &.pc-btn--outline:not([disabled]) {
    @apply bg-transparent border-grey-30;

    &:hover {
      @apply bg-grey-20;
    }

    &:active {
      @apply bg-grey-25;
    }
  }
  &.pc-btn--ghost:not([disabled]) {
    @apply text-blue-100 bg-transparent border-transparent;

    &:hover {
      @apply bg-grey-20;
    }

    &:active {
      @apply bg-grey-25;
    }
  }

  &.pc-btn--large {
    @apply text-xl px-6 py-3;
  }
  &.pc-btn--medium {
    @apply px-6 py-3 leading-snug;
  }
  &.pc-btn--small,
  &.pc-btn--small16 {
    @apply px-4 py-2 leading-snug;
  }
  &.pc-btn--xsmall {
    @apply text-sm px-2 py-1;
  }

  &.pc-btn--circle {
    @apply rounded-full flex items-center justify-center;

    &.pc-btn--large {
      @apply w-14 h-14;
    }

    &.pc-btn--medium {
      @apply w-12 h-12;
    }

    &.pc-btn--small,
    &.pc-btn--small16 {
      @apply w-10 h-10;
    }

    &.pc-btn--xsmall {
      @apply w-8 h-8;
    }

    .pc-btn-icon--wrapper {
      @apply w-5 h-5;
    }
  }

  &.pc-btn--disabled {
    @apply text-grey-45 bg-grey-30 border-grey-30 cursor-not-allowed;
  }
}

.pc-btn[data-theme='icing'] {
  @apply inline-block rounded-full box-border border border-transparent cursor-pointer relative overflow-hidden;

  &:focus {
    @apply outline-none shadow-outline-focus;
  }

  .pc-btn-inner {
    position: relative;
    z-index: 1;
  }
  .pc-btn-loader {
    @apply w-6 h-6;
  }
  .pc-btn-icon {
    min-width: 100%;
    min-height: 100%;
  }
  .pc-btn-icon--wrapper {
    @apply flex items-center w-6 h-6;
  }

  &.pc-btn--primary:not([disabled]) {
    @apply text-content-interactive-primary bg-interactive-brand;

    &:hover {
      background-color: var(--dt-pc-color-iteractive-brand-mix-hover-01);
    }
    &.pc-btn--loading,
    &:active {
      background-color: var(--dt-pc-color-iteractive-brand-mix-active-01);
    }
  }
  &.pc-btn--secondary:not([disabled]) {
    @apply text-content-interactive-secondary bg-interactive-secondary;

    &:hover {
      background-color: var(--dt-pc-color-iteractive-secondary-mix-hover-01);
    }
    &.pc-btn--loading,
    &:active {
      background-color: var(--dt-pc-color-iteractive-secondary-mix-active-01);
    }
  }
  &.pc-btn--outline:not([disabled]) {
    @apply text-content-interactive-tertiary border-strong;

    &:hover {
      @apply bg-hover-01;
    }

    &.pc-btn--loading,
    &:active {
      @apply bg-active-01;
    }
  }
  &.pc-btn--ghost:not([disabled]) {
    @apply text-content-link bg-transparent;

    &:hover {
      background-color: var(--dt-pc-color-backgound-default-mix-hover-01);
    }

    &.pc-btn--loading,
    &:active {
      background-color: var(--dt-pc-color-backgound-default-mix-active-01);
    }

    &.pc-btn.pc-btn--disabled,
    &.pc-btn.pc-btn--disabled:hover {
      @apply bg-transparent;
    }
  }

  &.pc-btn--large {
    padding: 13px var(--dt-spacing-e);
    @mixin interactive-l;
  }
  &.pc-btn--medium {
    padding: 9px var(--dt-spacing-e);
    @mixin interactive-l;
  }
  &.pc-btn--small {
    padding: 5px var(--dt-spacing-d);
    @mixin interactive-m;
  }

  &.pc-btn--small16 {
    padding: 7px var(--dt-spacing-d);
    @mixin interactive-s;
  }
  &.pc-btn--xsmall {
    padding: 5px var(--dt-spacing-b);
    @mixin interactive-xs;
  }

  &.pc-btn--small16,
  &.pc-btn--xsmall {
    .pc-btn-loader {
      @apply w-5 h-5;
    }

    &.pc-btn--primary:not([disabled]) {
      @apply text-content-interactive-primary bg-interactive-primary;

      &:hover {
        background-color: var(--dt-pc-color-iteractive-primary-mix-hover-02);
      }
      &.pc-btn--loading,
      &:active {
        background-color: var(--dt-pc-color-iteractive-primary-mix-active-02);
      }
    }
  }

  &.pc-btn--circle {
    @apply rounded-full flex items-center justify-center;

    &.pc-btn--large {
      @apply w-14 h-14 p-4;
    }
    &.pc-btn--medium {
      @apply w-12 h-12 p-3.5;
    }
    &.pc-btn--small {
      @apply w-10 h-10 p-2.5;
    }
    &.pc-btn--small16 {
      @apply w-10 h-10 p-2.5;
    }
    &.pc-btn--xsmall {
      @apply w-8 h-8 p-1.5;
    }
  }

  &.pc-btn--small,
  &.pc-btn--small16 {
    .pc-btn-icon--wrapper {
      @apply w-5 h-5;
    }
  }
  &.pc-btn--xsmall {
    .pc-btn-icon--wrapper {
      @apply w-4 h-4;
    }
  }

  &.pc-btn--disabled,
  &.pc-btn--disabled:hover {
    @apply bg-disabled-01 text-content-disabled cursor-not-allowed;
  }
}
</style>
