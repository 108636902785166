var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.getTag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          staticClass: "pc-btn",
          class: [
            "pc-btn--" + _vm.type,
            "pc-btn--" + _vm.size,
            {
              "pc-btn--loading": _vm.loading,
              "pc-btn--disabled": _vm.disabled,
              "pc-btn--circle": _vm.circle,
            },
          ],
          attrs: { "data-theme": _vm.themeName, disabled: _vm.disabled },
        },
        "component",
        _vm.getProps,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "pc-btn-inner" },
        [
          _vm.loading
            ? _c("pc-icon", {
                staticClass:
                  "pc-btn-loader animate-spin absolute inset-0 m-auto",
                attrs: { name: "loading" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex items-center justify-center text-center",
              class: { invisible: _vm.loading },
            },
            [
              _vm.icon && _vm.iconPosition === "leading"
                ? _c(
                    "span",
                    {
                      staticClass: "pc-btn-icon--wrapper",
                      class: _vm.circle ? "" : "mr-2",
                    },
                    [
                      _vm.icon
                        ? _c("pc-icon", {
                            staticClass: "pc-btn-icon",
                            attrs: { name: _vm.icon },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _vm.icon && _vm.iconPosition === "trailing"
                ? _c(
                    "span",
                    { staticClass: "pc-btn-icon--wrapper ml-2" },
                    [
                      _vm.icon
                        ? _c("pc-icon", {
                            staticClass: "pc-btn-icon",
                            attrs: { name: _vm.icon },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }