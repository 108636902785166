import { theme } from '../constants'

const THEME_STORAGE_KEY = 'je.pcweb.theme'

export const themes = {
  default: 'default',
  icing: 'icing'
}

export const getCurrentTheme = () => {
  const themeName = localStorage.getItem(THEME_STORAGE_KEY) ?? theme
  return themes[themeName] ?? themes.default
}

export const currentTheme = getCurrentTheme()

export const themeConfig = {
  current: getCurrentTheme(),
  list: themes,
  isIcing: getCurrentTheme() === themes.icing,
  pieDesignTokensVersion: '3.5.0'
}
