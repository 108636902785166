var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("g", { attrs: { "fill-rule": "evenodd" } }, [
        _c("g", [
          _c("g", [
            _c("g", [
              _c("path", {
                attrs: {
                  d: "M7.787 2.8V1.654c0-.288-.346-.428-.544-.224L5.45 3.216c-.128.128-.128.326 0 .454l1.785 1.786c.205.198.55.058.55-.23V4.08c2.12 0 3.84 1.722 3.84 3.84 0 .506-.095.998-.28 1.44-.097.23-.027.493.146.666.327.326.877.21 1.05-.218.237-.582.365-1.222.365-1.888 0-2.829-2.292-5.12-5.12-5.12zm0 8.96c-2.119 0-3.84-1.722-3.84-3.84 0-.506.096-.998.281-1.44.096-.23.026-.493-.147-.666-.326-.326-.877-.21-1.05.218-.236.582-.364 1.222-.364 1.888 0 2.829 2.29 5.12 5.12 5.12v1.146c0 .288.345.428.544.224l1.785-1.786c.128-.128.128-.326 0-.454l-1.785-1.786c-.199-.198-.544-.058-.544.23v1.146z",
                  transform:
                    "translate(-491 -522) translate(483 518) translate(8 4)",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }