var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "fill-current",
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_15_803)" } }, [
        _c("g", { attrs: { "clip-path": "url(#clip1_15_803)" } }, [
          _c("path", {
            attrs: {
              d: "M14.1255 7.34375H8.65674V1.875H7.34424V7.34375H1.87549V8.65625H7.34424V14.125H8.65674V8.65625H14.1255V7.34375Z",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_15_803" } }, [
          _c("rect", {
            attrs: {
              width: "14",
              height: "14",
              fill: "white",
              transform: "translate(1.00049 1)",
            },
          }),
        ]),
        _vm._v(" "),
        _c("clipPath", { attrs: { id: "clip1_15_803" } }, [
          _c("rect", {
            attrs: {
              width: "14",
              height: "14",
              fill: "white",
              transform: "translate(1.00049 1)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }