import { themeConfig } from './helpers/themeResolver'
import './set-public-path'
import 'tippy.js/dist/tippy.css'

/** PC Theme */
import(`./assets/themes/icing/css/index.css`)
export { themeConfig as PcThemeConfig }

/** PC Components */
export { default as PcNotification } from './components/Notification'
export { default as PcRating } from './components/Rating'
export { default as PcButton } from './components/Button'
export { default as PcPagination } from './components/Pagination'
export { default as PcProgress } from './components/Progress'
export { default as PcTag } from './components/Tag'
export { default as PcIcon } from './components/Icon'
export { default as PcModal } from './components/Modal'
export { default as PcSelect } from './components/Select'
export { default as PcCheckbox } from './components/Checkbox'
export { default as PcGlobalLink } from './components/GlobalLink'
export { default as PcPageTransition } from './components/PageTransition'
export { default as PcPageLayout } from './components/PageLayout'
export { default as PcMenuCard } from './components/MenuCard'
export { default as PcBackToTopButton } from './components/BackToTopButton'
export { default as PcCollapse } from './components/Collapse'
export { default as PcRadio } from './components/Radio'
export { default as PcChip } from './components/Chip'
export { default as PcToggle } from './components/Toggle'
export { default as PcErrorBoundary } from './components/ErrorBoundary'
export { default as PcStepper } from './components/Stepper'
export { default as PcSidebar } from './components/Sidebar'
export { default as PcTab } from './components/Tab'
export {
  LeftSideNavigation as PcLeftSideNavigation,
  LeftSideNavigationItem as PcLeftSideNavigationItem
} from './components/LeftSideNavigation'

export { OneLineList as PcOneLineList } from './components/List'

/** PC Pages */
export { default as PcErrorPage } from './pages/PcError'

/** PC Directives */
export { default as PcTooltip } from './directives/tooltip'
export { default as PcClickOutside } from './directives/clickOutside'

/** PC Mixins */
export { default as isMobile } from './mixins/breakpoint'
export { default as breakpoint } from './mixins/breakpoint'
export { default as pageTitle } from './mixins/pageTitle'
export { default as clickOutside } from './mixins/clickOutside'

/** PC Plugins */
export { default as PcToast } from './plugins/Toast'

/** Third party integrations */
export { default as FButton } from './components/FButton'
export {
  CollapseTransition,
  FadeTransition,
  SlideYDownTransition
} from 'vue2-transitions'
