<template>
  <div
    :data-theme="themeName"
    class="pc-collapse"
    :class="[{ 'pc-collapse--divider': divider }]"
  >
    <div
      class="pc-collapse-header"
      :class="`${disabledFrom}:hidden`"
      tabindex="0"
      data-test="card-header"
      @click="toggle"
    >
      <div class="flex justify-between items-center">
        <div class="flex pr-4">
          <div v-if="$slots.icon" class="flex items-center justify-center mr-4">
            <slot name="icon"></slot>
          </div>
          <h6 class="pc-collapse-header-text">{{ headerText }}</h6>
        </div>
        <pc-icon
          name="chevronDown"
          class="pc-collapse-icon transition duration-300 linear"
          :class="[{ 'transform rotate-180': isOpened }, arrowColor]"
        />
      </div>
      <p v-if="secondaryText" class="pc-collapse-secondary-text mt-1">
        {{ secondaryText }}
      </p>
    </div>
    <collapse-transition>
      <div
        v-if="isOpened || !collapsible"
        class="pc-collapse-body"
        data-test="card-body"
      >
        <slot></slot>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'
import { BREAKPOINTS } from '../../constants'
import PcIcon from '../Icon'
import theme from '../../mixins/theme'
import { themeConfig } from '../../helpers/themeResolver'

const defaultProps = {
  disabledFrom: themeConfig.isIcing ? 'tablet' : 'sm',
  arrowColor: themeConfig.isIcing ? 'text-content-default' : 'text-blue-100'
}

export default {
  name: 'PcCollapse',
  components: {
    PcIcon,
    CollapseTransition
  },
  mixins: [theme],
  props: {
    disabledFrom: {
      type: String || null,
      default: defaultProps.disabledFrom
    },
    divider: {
      type: Boolean,
      default: true
    },
    headerText: {
      type: String,
      default: ''
    },
    secondaryText: {
      type: String,
      default: ''
    },
    arrowColor: {
      type: String,
      default: defaultProps.arrowColor
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpened: this.isExpanded,
      breakpoints: BREAKPOINTS,
      collapsible: false
    }
  },
  watch: {
    isExpanded(status) {
      this.isOpened = status
    }
  },
  created() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    toggle() {
      if (!this.collapsible) return
      this.isOpened = !this.isOpened
    },
    handleResize() {
      if (this.disabledFrom === null) {
        this.collapsible = true
        return
      }
      this.collapsible = window.innerWidth < this.breakpoints[this.disabledFrom]
    }
  }
}
</script>

<style scoped>
@import '../../../src/assets/themes/icing/css/mixins/typography.pcss';
.pc-collapse[data-theme='default'] {
  .pc-collapse-header {
    @apply px-4 py-3 bg-white;
  }

  &.pc-collapse--divider .pc-collapse-header {
    @apply border-b border-grey-30;
  }

  .pc-collapse-header-text {
    @apply font-bold;
  }
}

.pc-collapse[data-theme='icing'] {
  .pc-collapse-header {
    @apply mb-0.5 p-4 bg-container-default cursor-pointer duration-200 transition-colors;

    &:hover {
      background-color: var(--dt-pc-color-container-default-mix-hover-01);
    }

    &:active {
      background-color: var(--dt-pc-color-container-default-mix-active-01);
    }

    &:focus {
      @apply shadow-outline-focus;
    }
  }

  &.pc-collapse--divider .pc-collapse-header {
    @apply border-b;
    border-color: var(--dt-color-divider-default);
  }

  .pc-collapse-header-text {
    @mixin heading-s;
  }
  .pc-collapse-secondary-text {
    @apply text-content-subdued;
    @mixin body-s;

    @screen laptop {
      @mixin body-l;
    }
  }
}
</style>
