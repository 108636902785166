var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5ZM2.16667 8C2.1669 6.08027 3.11165 4.28356 4.6931 3.19527C6.27455 2.10699 8.29021 1.86647 10.0833 2.55208C7.8259 3.41611 6.33518 5.58286 6.33518 8C6.33518 10.4171 7.8259 12.5839 10.0833 13.4479C8.29021 14.1335 6.27455 13.893 4.6931 12.8047C3.11165 11.7164 2.1669 9.91973 2.16667 8Z",
          fill: "#5E6B77",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }