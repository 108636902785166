var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 16 16",
        width: "16",
        height: "16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", { attrs: { d: "M8.65625 5.375H7.34375V8H8.65625V5.375Z" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M8 10.625C8.48325 10.625 8.875 10.2332 8.875 9.75C8.875 9.26675 8.48325 8.875 8 8.875C7.51675 8.875 7.125 9.26675 7.125 9.75C7.125 10.2332 7.51675 10.625 8 10.625Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M13.4075 13.0312H2.5925C2.31923 13.0326 2.05081 12.9592 1.81634 12.8188C1.58188 12.6784 1.39034 12.4765 1.2625 12.235C1.12603 11.987 1.05814 11.707 1.06583 11.4241C1.07352 11.1411 1.15651 10.8653 1.30625 10.625L6.71375 1.9625C6.84547 1.73783 7.03324 1.5512 7.25869 1.42083C7.48415 1.29047 7.73957 1.22084 8 1.21875C8.25874 1.21903 8.51304 1.28601 8.73835 1.41322C8.96366 1.54044 9.15236 1.72359 9.28625 1.945L14.6937 10.625C14.8401 10.8712 14.9174 11.1523 14.9174 11.4388C14.9174 11.7252 14.8401 12.0063 14.6937 12.2525C14.5671 12.4843 14.3815 12.6784 14.1556 12.8152C13.9296 12.952 13.6715 13.0265 13.4075 13.0312ZM8 2.53125C7.96392 2.53059 7.92838 2.54008 7.89744 2.55865C7.86649 2.57722 7.84139 2.6041 7.825 2.63625L2.4175 11.3075C2.39139 11.3527 2.37764 11.404 2.37764 11.4562C2.37764 11.5085 2.39139 11.5598 2.4175 11.605C2.43414 11.6395 2.46026 11.6685 2.49281 11.6886C2.52535 11.7088 2.56297 11.7192 2.60125 11.7187H13.4075C13.4458 11.7192 13.4834 11.7088 13.5159 11.6886C13.5485 11.6685 13.5746 11.6395 13.5912 11.605C13.6174 11.5598 13.6311 11.5085 13.6311 11.4562C13.6311 11.404 13.6174 11.3527 13.5912 11.3075L8.175 2.63625C8.1586 2.6041 8.1335 2.57722 8.10256 2.55865C8.07161 2.54008 8.03608 2.53059 8 2.53125Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }