<template>
  <div :data-theme="themeName" class="pc-stepper">
    <div class="flex justify-center" data-test="pc-stepper">
      <pc-button
        type="secondary"
        :size="buttonSize"
        :disabled="hasReachedMinimum"
        data-test="pc-stepper-decrease"
        icon="minus"
        circle
        class="minus"
        @click="decrease"
      />
      <div class="flex flex-col flex-1 text-center">
        <span
          class="pc-stepper-value"
          data-test="pc-stepper-value"
          @click="edit"
        >
          <template v-if="!editMode">
            <slot name="value">{{ stepperValue }}</slot>
          </template>
          <input
            v-else
            ref="input"
            v-model.number="stepperValue"
            class="pc-stepper-input"
            type="number"
            @blur="editMode = false"
          />
        </span>
        <span class="pc-stepper-label"><slot name="label" /></span>
      </div>
      <pc-button
        type="secondary"
        :size="buttonSize"
        :disabled="hasReachedMaximum"
        data-test="pc-stepper-increase"
        icon="plus"
        class="plus"
        circle
        @click="increase"
      />
    </div>
  </div>
</template>

<script>
import breakpoint from '../../mixins/breakpoint'
import PcButton from '../Button'
import theme from '../../mixins/theme'

export default {
  name: 'PcStepper',
  components: {
    PcButton
  },
  mixins: [breakpoint, theme],
  props: {
    value: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    },
    step: {
      type: Number,
      default: 1
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { editMode: false }
  },
  computed: {
    buttonSize() {
      if (this.themeConfig.isIcing) return 'large'
      return this.isMobile ? 'small' : 'large'
    },
    hasReachedMaximum() {
      return this.value >= this.max
    },
    hasReachedMinimum() {
      return this.value <= this.min
    },
    stepperValue: {
      get() {
        return this.value
      },
      set(value) {
        let inputValue = parseInt(value)
        if (isNaN(inputValue)) {
          inputValue = this.min
        }
        const newValue = Math.min(Math.max(inputValue, this.min), this.max)
        this.$emit('input', newValue)
      }
    }
  },
  created() {
    if (this.value > this.max) {
      this.stepperValue = this.max
    }
    if (this.value < this.min) {
      this.stepperValue = this.min
    }
  },
  methods: {
    increase() {
      if (this.hasReachedMaximum) {
        return
      }
      this.stepperValue = this.value + this.step
    },
    decrease() {
      if (this.hasReachedMinimum) {
        return
      }
      this.stepperValue = this.value - this.step
    },
    edit() {
      if (this.editable) {
        this.editMode = true
        this.$nextTick().then(() => this.$refs.input.focus())
      }
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/form-input.pcss';

.pc-stepper[data-theme='default'] {
  .pc-stepper-value {
    @apply p-2 border border-grey-30 mx-2 h-10;

    @screen sm {
      @apply p-4 h-14;
    }
  }

  .pc-stepper-input {
    @apply text-center w-full;

    &:focus {
      @apply outline-none;
    }
  }
  .pc-stepper-label {
    @apply mt-2;
  }
}

.pc-stepper[data-theme='icing'] {
  .pc-stepper-value {
    @apply relative p-4 border border-interactive-form rounded-radius-c mx-2 h-14;
  }

  .pc-stepper-input {
    @apply absolute inset-0 text-center w-full rounded-radius-c outline-none;

    &:focus {
      @apply shadow-outline-focus;
    }
  }

  .pc-stepper-label {
    @apply mt-1 text-content-subdued;
    @mixin body-s;
  }
}
</style>
