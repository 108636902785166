import tippy from 'tippy.js'

export default (options = {}) => {
  const sharedOpts = {
    animation: 'shift-toward',
    arrow: true,
    appendTo: document.body,
    theme: 'pc',
    popperOptions: {
      modifiers: [
        {
          name: 'flip',
          enabled: true,
          options: {
            fallbackPlacements: [
              'right',
              'left',
              'right',
              'top',
              'bottom',
              'top'
            ]
          }
        }
      ]
    }
  }

  const stickyOpts = {
    interactive: true,
    trigger: 'click',
    onShow: (instance) => {
      const popper = instance.popper
      const btn = document.createElement('button')
      btn.innerHTML =
        '<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>'
      btn.classList = 'pc-tooltip__close'
      btn.onclick = () => {
        instance.hide()
      }
      popper.classList.add('pc-tooltip--sticky')
      const content = popper.querySelector('.tippy-content')
      content.appendChild(btn)
      popper.setAttribute('tabindex', 0)
      popper.focus()
    },
    onHidden: (instance) => {
      const popper = instance.popper
      popper.classList.remove('pc-tooltip--sticky')
      popper.querySelector('.pc-tooltip__close').remove()
    }
  }
  const init = (el, { value = {}, modifiers }) => {
    let options = Object.assign(
      {},
      sharedOpts,
      value,
      modifiers.sticky ? stickyOpts : {}
    )
    if (el._tippy) {
      el._tippy.destroy()
      tippy(el, options)
    } else {
      tippy(el, options)
    }
  }

  const unbind = (el) => {
    if (el._tippy) {
      el._tippy.destroy()
    }
  }

  return {
    inserted: init,
    componentUpdated: init,
    unbind
  }
}
