var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.865 12.4888C5.70071 12.487 5.53848 12.452 5.38809 12.3858C5.2377 12.3197 5.10228 12.2237 4.99 12.1038L1.875 8.65626L2.855 7.78126L5.8825 11.15L13.1362 3.32751L14.0988 4.20251L6.74875 12.0775C6.63647 12.1975 6.50105 12.2934 6.35066 12.3596C6.20027 12.4258 6.03804 12.4608 5.87375 12.4625L5.865 12.4888Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }