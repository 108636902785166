<template>
  <link-helper :href="href" class="menu-card" v-on="$listeners">
    <div class="flex flex-row items-center">
      <div class="flex items-start flex-wrap flex-grow">
        <div
          v-if="$slots.icon || cardIcon"
          class="
            menu-card-icon
            flex
            items-center
            justify-center
            w-6
            h-6
            laptop:w-8 laptop:h-8
            mr-4
          "
        >
          <slot name="icon"><icon :name="cardIcon" /></slot>
        </div>
        <div class="flex-1">
          <div
            v-if="$slots.cardHeading || cardHeading"
            class="inline-flex items-center mr-2"
          >
            <slot name="cardHeading">
              <h3 class="pc-heading-s">{{ cardHeading }}</h3>
            </slot>
          </div>
          <div v-if="$slots.tags" class="menu-card-tags inline-block">
            <slot name="tags" />
          </div>
          <p v-if="$slots.default || cardContent" class="mt-1">
            <slot>{{ cardContent }}</slot>
          </p>
        </div>
      </div>
      <pc-icon name="chevronRight" class="w-6 h-6 flex-shrink-0" />
    </div>
  </link-helper>
</template>

<script>
import PcIcon from '../Icon'
import { LinkHelper } from '../Link'

export default {
  name: 'PcMenuCard',
  components: {
    PcIcon,
    LinkHelper
  },
  props: {
    cardHeading: {
      type: String,
      default: ''
    },
    cardContent: {
      type: String,
      default: ''
    },
    cardIcon: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.menu-card {
  @apply bg-container-default rounded-radius-c overflow-hidden block p-4 shadow-elevation-01
  transition duration-200 ease-out;
}

.menu-card:hover {
  background-color: var(--dt-pc-color-backgound-default-mix-hover-01);
}

.menu-card-icon svg {
  min-height: 100%;
  min-width: 100%;
}

.menu-card-tags > :not(template) {
  margin-right: 2px;
}
</style>
