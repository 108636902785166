var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pc-stepper", attrs: { "data-theme": _vm.themeName } },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-center",
          attrs: { "data-test": "pc-stepper" },
        },
        [
          _c("pc-button", {
            staticClass: "minus",
            attrs: {
              type: "secondary",
              size: _vm.buttonSize,
              disabled: _vm.hasReachedMinimum,
              "data-test": "pc-stepper-decrease",
              icon: "minus",
              circle: "",
            },
            on: { click: _vm.decrease },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex flex-col flex-1 text-center" }, [
            _c(
              "span",
              {
                staticClass: "pc-stepper-value",
                attrs: { "data-test": "pc-stepper-value" },
                on: { click: _vm.edit },
              },
              [
                !_vm.editMode
                  ? [_vm._t("value", [_vm._v(_vm._s(_vm.stepperValue))])]
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.stepperValue,
                          expression: "stepperValue",
                          modifiers: { number: true },
                        },
                      ],
                      ref: "input",
                      staticClass: "pc-stepper-input",
                      attrs: { type: "number" },
                      domProps: { value: _vm.stepperValue },
                      on: {
                        blur: [
                          function ($event) {
                            _vm.editMode = false
                          },
                          function ($event) {
                            return _vm.$forceUpdate()
                          },
                        ],
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.stepperValue = _vm._n($event.target.value)
                        },
                      },
                    }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "pc-stepper-label" },
              [_vm._t("label")],
              2
            ),
          ]),
          _vm._v(" "),
          _c("pc-button", {
            staticClass: "plus",
            attrs: {
              type: "secondary",
              size: _vm.buttonSize,
              disabled: _vm.hasReachedMaximum,
              "data-test": "pc-stepper-increase",
              icon: "plus",
              circle: "",
            },
            on: { click: _vm.increase },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }