<template>
  <div
    :data-theme="themeName"
    class="pc-notification space-x-4"
    :class="[`pc-notification--${type}`, title ? '' : 'items-center']"
  >
    <icon
      v-if="getIconName"
      class="pc-notification-icon w-6 h-6 flex-shrink-0"
      :name="getIconName"
    />
    <div class="w-full">
      <p v-if="title" class="pc-notification-title">
        {{ title }}
      </p>
      <p>
        <!-- @slot Default slot for notification text -->
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
import icon from '../Icon'
import theme from '../../mixins/theme'
import { NOTIFICATION_TYPES } from './constants'

export default {
  name: 'PcNotification',
  components: {
    icon
  },
  mixins: [theme],
  props: {
    /**
     * Type of the notification
     * `(error, success, warning, info)`
     */
    type: {
      type: String,
      validator: (value) =>
        Object.prototype.hasOwnProperty.call(NOTIFICATION_TYPES, value),
      required: true
    },
    /**
     * Adds title to notification
     */
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconClass() {
      return NOTIFICATION_TYPES[this.type].icon
    },
    getIconName() {
      return this.icon || NOTIFICATION_TYPES[this.type].iconName
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';

.pc-notification[data-theme='default'] {
  @apply w-full p-4 rounded-sm flex justify-start;

  .pc-notification-title {
    @apply font-bold leading-snug mb-1;
    @screen sm {
      @apply text-xl;
    }
  }

  &.pc-notification {
    &--success {
      @apply bg-green-10;
      .pc-notification-icon {
        @apply text-green-100;
      }
    }
    &--error {
      @apply bg-red-10;
      .pc-notification-icon {
        @apply text-red-100;
      }
    }
    &--info {
      @apply bg-blue-50;
      .pc-notification-icon {
        @apply text-blue-100;
      }
    }
    &--warning {
      @apply bg-yellow-10;
      .pc-notification-icon {
        @apply text-yellow-100;
      }
    }
  }
}
.pc-notification[data-theme='icing'] {
  @apply w-full p-4 rounded-radius-c flex justify-start;

  .pc-notification-title {
    @apply leading-snug mb-1;
    @mixin heading-s;
  }

  &.pc-notification {
    &--success {
      @apply bg-support-positive-02;
      .pc-notification-icon {
        @apply text-support-positive;
      }
    }
    &--error {
      @apply bg-support-error-02;
      .pc-notification-icon {
        @apply text-support-error;
      }
    }
    &--info {
      @apply bg-support-info-02;
      .pc-notification-icon {
        @apply text-support-info;
      }
    }
    &--warning {
      @apply bg-support-warning-02;

      .pc-notification-icon {
        @apply text-support-warning;
      }
    }
    &--neutral {
      @apply bg-container-subtle;
    }
    &--neutral-alternative {
      @apply bg-container-default;
    }
  }
}
</style>
