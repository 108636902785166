var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pc-checkbox",
      class: [
        {
          "pc-checkbox--error": _vm.validationMessage,
        },
      ],
      attrs: { "data-theme": _vm.themeName },
    },
    [
      _c("input", {
        staticClass: "pc-checkbox-field",
        attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
        domProps: { value: _vm.value, checked: _vm.isChecked },
        on: { change: _vm.onCheckboxChange },
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "pc-checkbox-label", attrs: { for: _vm.id } },
        [
          _c(
            "div",
            { staticClass: "pc-dummy-check" },
            [
              _c("pc-icon", {
                staticClass: "pc-check-icon",
                attrs: { name: _vm.iconName },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("default", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm.validationMessage
        ? _c(
            "p",
            { staticClass: "pc-checkbox-error-message" },
            [
              _c("pc-icon", {
                staticClass: "mr-1 w-5 h-5",
                attrs: { name: "error" },
              }),
              _vm._v("\n    " + _vm._s(_vm.validationMessage) + "\n  "),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }