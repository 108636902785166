<template>
  <transition
    enter-active-class="transition transform duration-300 ease-out"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition transform duration-300 ease-out"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="value"
      :data-theme="themeName"
      class="pc-modal fixed z-50 inset-0 flex items-center justify-center"
      tabindex="-1"
      @keyup.esc.prevent="handleEscapeKey"
    >
      <div
        class="pc-modal-overlay absolute inset-0"
        @click="handleBackdropClick"
      />
      <div
        ref="modal"
        class="
          pc-modal-wrapper
          absolute
          overflow-y-auto
          sm:h-auto
          md:w-5/6
          lg:w-3/6
        "
        :class="wrapperClasses"
      >
        <div class="pc-modal-title relative">
          <slot name="title"></slot>
          <button
            v-if="closable"
            class="pc-modal-close absolute right-0 top-0 cursor-pointer"
            @click="close"
          >
            <pc-icon class="w-6 h-6 fill-current" name="close" />
          </button>
        </div>
        <div class="pc-modal-body relative">
          <slot name="content">
            <p>Default content</p>
          </slot>
        </div>
        <div v-if="$slots.footer" class="pc-modal-footer relative">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import theme from '../../mixins/theme'
import breakpoint from '../../mixins/breakpoint'
import PcIcon from '../Icon/'

const OVERFLOW_HIDDEN_CLASS = 'overflow-hidden'

export default {
  name: 'PcModal',
  components: {
    PcIcon
  },
  mixins: [breakpoint, theme],
  props: {
    /**
     * @model
     * Default v-model value
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * Size of modal window
     * `(small, medium, large)`
     */
    size: {
      type: String,
      validator: (val) => ['small', 'regular', 'medium', 'large'].includes(val)
    },
    /**
     * Added when modal should have close button
     */
    closable: {
      type: Boolean,
      default: true
    },
    /**
     * Added when modal should be closed on esc button
     */
    escapable: {
      type: Boolean,
      default: false
    },
    /**
     * Added to show/hide backdrop
     */
    hideBackdrop: {
      type: Boolean,
      default: false
    },
    /**
     * Added when click on backdrop should hide modal
     */
    closeOnBackdrop: {
      type: Boolean,
      default: true
    },
    isFullScreenOnMobile: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    wrapperClasses() {
      const mobileSizeClasses =
        this.isMobile && !this.isFullScreenOnMobile
          ? 'h-auto w-5/6'
          : 'h-full w-full'
      return `panel--${this.size} ${mobileSizeClasses}`
    }
  },
  mounted() {
    this.$watch(
      'value',
      () => {
        this.setModalState(this.value)
        if (this.value) {
          this.trapFocus()
        }
      },
      { immediate: true }
    )
  },
  beforeDestroy() {
    this.setModalState(false)
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    handleEscapeKey() {
      if (!this.escapable) return
      this.close()
    },
    handleBackdropClick() {
      if (!this.closeOnBackdrop) return
      this.close()
    },
    setModalState(value) {
      if (value) {
        this.showModal = value
        this.$nextTick(() => {
          this.disableScroll()
        })
      } else {
        this.enableScroll()
        if (this.$el instanceof HTMLElement) {
          setTimeout(() => {
            this.showModal = value
          }, 300)
        }
      }
    },
    enableScroll() {
      document.body.classList.remove(OVERFLOW_HIDDEN_CLASS)
    },
    disableScroll() {
      document.body.classList.add(OVERFLOW_HIDDEN_CLASS)
    },
    trapFocus() {
      const { modal } = this.$refs
      const focusableElements = modal.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      )
      const {
        0: firstFocusableElement,
        [focusableElements.length - 1]: lastFocusableElement
      } = focusableElements
      modal.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9
        if (!isTabPressed) {
          return
        }
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus()
            e.preventDefault()
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus()
            e.preventDefault()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';

.panel--small {
  max-width: 16rem;
}
.panel--regular {
  max-width: 30rem;
}
.panel--medium {
  max-width: 40rem;
}
.panel--large {
  max-width: 60rem;
}

.pc-modal[data-theme='default'] {
  .pc-modal-overlay {
    @apply bg-grey-100 opacity-50;
  }

  .pc-modal-close {
    @apply text-grey-50;
  }

  .pc-modal-wrapper {
    @apply bg-white rounded-sm p-8;
  }

  .pc-modal-title {
    @apply font-bold text-3xl;
  }
}
.pc-modal[data-theme='icing'] {
  .pc-modal-overlay {
    @apply bg-overlay;
  }

  .pc-modal-wrapper {
    @apply py-4;

    @screen laptop {
      @apply py-6;
    }
  }

  .pc-modal-title,
  .pc-modal-body,
  .pc-modal-footer {
    @apply px-4;
    @screen laptop {
      @apply px-6;
    }
  }

  .pc-modal-close {
    @apply text-content-default mr-4;
    @screen laptop {
      @apply mr-6;
    }
  }

  .pc-modal-wrapper {
    @apply bg-container-default shadow-elevation-04 flex flex-col;
    @screen tablet {
      @apply rounded-radius-d;
    }
  }

  .pc-modal-title {
    @apply mb-4;
    @mixin heading-m;
  }

  .pc-modal-body {
    @apply flex-1;
  }

  .pc-modal-footer {
    @apply pt-4 shadow-elevation-05;

    @screen tablet {
      @apply pt-6 shadow-none;
    }
  }
}
</style>
