import id from '../helpers/randomId'

export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    /**
     * @model
     * Required v-model prop
     */
    value: {
      type: undefined,
      default: false
    },
    /**
     * Text for checkbox label
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Disabled status
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Validation error message
     */
    validationMessage: {
      type: String,
      default: ''
    }
  },
  computed: {
    id,
    isChecked() {
      if (this.$attrs.modelValue instanceof Array) {
        return this.$attrs.modelValue.includes(this.value)
      }
      return this.$attrs.modelValue
    }
  },
  methods: {
    onCheckboxChange() {
      const isChecked = event.target.checked
      if (this.$attrs.modelValue instanceof Array) {
        const newValue = [...this.$attrs.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', !!isChecked)
      }
    }
  }
}
