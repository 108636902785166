<template>
  <div
    class="
      container
      box-border
      mt-4
      md:mt-8
      flex
      items-center
      flex-col
      space-y-8
    "
  >
    <error-icon class="mt-4 md:w-4/6" />
    <slot>
      <p class="font-bold text-2xl md:text-3xl text-center">
        {{ $t(errorMessage) }}
      </p>
    </slot>
  </div>
</template>

<script>
import tenantConfigs from '../tenants'
import ErrorIcon from './ErrorIcon.vue'

export default {
  props: {
    tenant: {
      type: String,
      default: 'uk'
    },

    errorStatus: {
      type: Number,
      default: null
    }
  },
  components: {
    ErrorIcon
  },
  data() {
    return {
      tenantConfigs
    }
  },
  computed: {
    errorMessage() {
      if (this.errorStatus === 404) return 'error_not_found'
      return 'error_unavailable'
    }
  },
  watch: {
    tenant: {
      immediate: true,
      handler(tenant) {
        this.setupLocale(tenant)
      }
    }
  },
  methods: {
    setupLocale(tenant) {
      if (!this.$i18n) return

      const { locale, messages: $i18nMessages } = this.$i18n
      const localeMessages = $i18nMessages[locale]

      const { messages: tenantMessages } =
        this.tenantConfigs[locale] || this.tenantConfigs[tenant]

      this.$i18n.setLocaleMessage(locale, {
        ...localeMessages,
        ...tenantMessages
      })
    }
  }
}
</script>
