var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: "rkyjhi90fa",
            d: "M12 4.667v8c0 .736-.597 1.333-1.333 1.333H5.333C4.597 14 4 13.403 4 12.667v-8h8zM9.667 2l.666.667h2.334V4H3.333V2.667h2.334L6.333 2h3.334z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { "fill-rule": "evenodd" } }, [
        _c("g", [
          _c("g", [
            _c(
              "g",
              {
                attrs: {
                  transform:
                    "translate(-501 -986) translate(493 982) translate(8 4)",
                },
              },
              [_c("use", { attrs: { "xlink:href": "#rkyjhi90fa" } })]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }