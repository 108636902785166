var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex items-center justify-center md:justify-between flex-col lg:flex-row",
    },
    [
      _c("span", { staticClass: "leading-normal text-grey-50" }, [
        _c("span", { staticClass: "font-bold" }, [
          _vm._v(_vm._s(_vm.startValue) + " - " + _vm._s(_vm.endValue)),
        ]),
        _vm._v("\n    " + _vm._s(_vm.of) + " "),
        _c("span", { staticClass: "font-bold" }, [_vm._v(_vm._s(_vm.total))]),
        _vm._v(" " + _vm._s(_vm.items)),
      ]),
      _vm._v(" "),
      _vm.pageCount > 1
        ? [
            _c(
              "div",
              {
                staticClass:
                  "flex mt-4 px-2 w-full justify-between items-center md:hidden",
              },
              [
                _c("span", { on: { click: _vm.decrease } }, [
                  _c(
                    "svg",
                    {
                      staticClass: "w-6 h-6 text-grey-50",
                      attrs: {
                        fill: "none",
                        stroke: "currentColor",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          d: "M15 19l-7-7 7-7",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex items-center space-x-1" }, [
                  _c("span", { staticClass: "text-grey-50 font-bold" }, [
                    _vm._v("Page"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex items-center relative" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.page,
                            expression: "page",
                          },
                        ],
                        staticClass:
                          "select border border-grey-40 box-border appearance-none outline-none bg-transparent leading-normal h-10 pl-4 pr-8",
                        attrs: { name: "page", id: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.page = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.pageCount, function (page) {
                        return _c(
                          "option",
                          { key: page, domProps: { value: page } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(page) +
                                " " +
                                _vm._s(_vm.of) +
                                " " +
                                _vm._s(_vm.pageCount) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass:
                          "absolute right-0 mr-4 w-3 h-2 pointer-events-none text-grey-50",
                        attrs: {
                          fill: "currentColor",
                          viewBox: "0 0 12 6",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            transform: "rotate(90 5.836 2.983)",
                            d: "M3.203-1.798c-.13.128-.202.303-.202.486 0 .182.073.357.202.486L7.01 2.983 3.2 6.793C3.074 6.92 3 7.095 3 7.277s.073.357.202.486c.128.13.303.202.486.202.182 0 .357-.073.486-.202L8.47 3.47c.13-.13.202-.304.202-.486s-.073-.357-.202-.486L4.175-1.798C4.046-1.928 3.87-2 3.689-2c-.183 0-.357.073-.486.202z",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("span", { on: { click: _vm.increase } }, [
                  _c(
                    "svg",
                    {
                      staticClass: "w-6 h-6 text-grey-50",
                      attrs: {
                        fill: "none",
                        stroke: "currentColor",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          d: "M9 5l7 7-7 7",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "items-center hidden md:flex" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "rounded-full focus:outline-none focus:shadow-outline w-8 h-8 inline-flex items-center justify-center hover:bg-grey-30",
                    attrs: { tabindex: _vm.calculateTabindex(1) },
                    on: { click: _vm.goFirst },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "w-4 h-4 text-grey-50 cursor-pointer",
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M11 19l-7-7 7-7m8 14l-7-7 7-7",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("paginate", {
                  attrs: {
                    "page-count": _vm.pageCount,
                    "prev-text":
                      '<svg class="w-4 h-4 text-grey-50" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>',
                    "next-text":
                      '<svg class="w-4 h-4 text-grey-50" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>',
                    "last-button-text": "olololo",
                    "page-class":
                      "rounded-full w-8 h-8 inline-flex text-sm font-bold leading-tight hover:bg-grey-30 cursor-pointer",
                    "prev-class": "w-8 h-8",
                    "next-class": "w-8 h-8",
                    "page-link-class":
                      "focus:outline-none focus:shadow-outline rounded-full flex items-center justify-center w-full",
                    "next-link-class":
                      "w-full h-full rounded-full inline-flex items-center justify-center hover:bg-grey-30 focus:outline-none focus:shadow-outline",
                    "prev-link-class":
                      "w-full h-full rounded-full inline-flex items-center justify-center hover:bg-grey-30 focus:outline-none focus:shadow-outline",
                    "active-class": "text-blue-100 bg-blue-50 hover:bg-blue-50",
                    "container-class": "flex space-x-1",
                  },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "rounded-full focus:outline-none focus:shadow-outline w-8 h-8 inline-flex items-center justify-center hover:bg-grey-30",
                    attrs: { tabindex: _vm.calculateTabindex(_vm.pageCount) },
                    on: { click: _vm.goLast },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "w-4 h-4 text-grey-50 cursor-pointer",
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M13 5l7 7-7 7M5 5l7 7-7 7",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }