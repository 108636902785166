export const NOTIFICATION_TYPES = {
  error: {
    iconName: 'error'
  },
  success: {
    iconName: 'success'
  },
  info: {
    iconName: 'info'
  },
  warning: {
    iconName: 'warning'
  },
  neutral: {
    iconName: null
  },
  'neutral-alternative': {
    iconName: null
  }
}
