<template>
  <error-boundary
    v-if="showComponent"
    v-bind="$attrs"
    :stopPropagation="false"
    v-on="$listeners"
    @on-error="handleComponentError"
  >
    <slot />
  </error-boundary>
</template>

<script>
import ErrorBoundary from '@justeat/f-error-boundary'

export default {
  components: {
    ErrorBoundary
  },
  props: {
    hideOnError: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hasError: false
  }),
  computed: {
    showComponent() {
      return this.hideOnError ? !this.hasError : true
    }
  },
  methods: {
    handleComponentError() {
      this.hasError = true
    }
  }
}
</script>
