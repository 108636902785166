var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 16 16",
        width: "20",
        height: "20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8 14.7813C6.6588 14.7813 5.34771 14.3835 4.23254 13.6384C3.11737 12.8933 2.2482 11.8342 1.73494 10.5951C1.22169 9.35596 1.0874 7.99248 1.34905 6.67705C1.61071 5.36161 2.25656 4.15331 3.20494 3.20494C4.15331 2.25656 5.36161 1.61071 6.67705 1.34905C7.99248 1.0874 9.35596 1.22169 10.5951 1.73494C11.8342 2.2482 12.8933 3.11737 13.6384 4.23254C14.3835 5.34771 14.7813 6.6588 14.7813 8C14.7789 9.79779 14.0637 11.5213 12.7925 12.7925C11.5213 14.0637 9.79779 14.7789 8 14.7813ZM8 2.53125C6.91839 2.53125 5.86106 2.85199 4.96173 3.4529C4.0624 4.05382 3.36145 4.90792 2.94754 5.9072C2.53362 6.90649 2.42532 8.00607 2.63633 9.0669C2.84735 10.1277 3.36819 11.1022 4.13301 11.867C4.89783 12.6318 5.87227 13.1527 6.9331 13.3637C7.99394 13.5747 9.09352 13.4664 10.0928 13.0525C11.0921 12.6386 11.9462 11.9376 12.5471 11.0383C13.148 10.1389 13.4688 9.08162 13.4688 8C13.4688 7.28184 13.3273 6.5707 13.0525 5.9072C12.7776 5.2437 12.3748 4.64083 11.867 4.13301C11.3592 3.62519 10.7563 3.22237 10.0928 2.94754C9.4293 2.67271 8.71817 2.53125 8 2.53125Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M7.055 4.63125C7.67308 4.45793 8.32692 4.45793 8.945 4.63125L8.46375 8.875H7.53625L7.055 4.63125ZM8.875 10.625C8.875 10.7981 8.82368 10.9672 8.72754 11.1111C8.63139 11.255 8.49474 11.3672 8.33485 11.4334C8.17496 11.4996 7.99903 11.517 7.8293 11.4832C7.65956 11.4494 7.50365 11.3661 7.38128 11.2437C7.25891 11.1213 7.17558 10.9654 7.14181 10.7957C7.10805 10.626 7.12538 10.45 7.19161 10.2902C7.25783 10.1303 7.36998 9.99361 7.51388 9.89746C7.65777 9.80132 7.82694 9.75 8 9.75C8.23207 9.75 8.45463 9.84219 8.61872 10.0063C8.78281 10.1704 8.875 10.3929 8.875 10.625Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }