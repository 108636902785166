export const TAG_TYPES = {
  success: 'text-green-100 bg-green-10',
  info: 'bg-blue-10',
  warning: 'bg-yellow-10',
  error: 'text-red-100 bg-red-10',
  brand: 'bg-orange-10',
  neutral: 'bg-grey-30',
  outline: 'border border-grey-30',
  'strong-neutral': 'bg-container-inverse text-content-inverse',
  'strong-green': 'bg-support-positive text-content-light',
  'strong-red': 'bg-support-error text-content-light',
  'strong-yellow': 'bg-support-warning text-content-interactive-dark',
  'subtle-neutral-alternative': 'bg-container-default',
  'subtle-neutral': 'bg-container-strong',
  'subtle-outline': 'bg-transparent border border-strong',
  'subtle-ghost': 'bg-transparent',
  'subtle-blue': 'bg-support-info-02',
  'subtle-green': 'bg-support-positive-02',
  'subtle-yellow': 'bg-support-warning-02',
  'subtle-red': 'bg-support-error-02',
  'subtle-brand': 'bg-support-brand-02'
}
