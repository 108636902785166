var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pc-toggle", attrs: { "data-theme": _vm.themeName } },
    [
      _c("input", {
        staticClass: "pc-toggle-input absolute -left-9999",
        attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
        domProps: { value: _vm.value, checked: _vm.isChecked },
        on: { change: _vm.onCheckboxChange },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "pc-toggle-outer",
          class: [_vm.disabled ? "cursor-not-allowed" : "cursor-pointer"],
          attrs: { for: _vm.id },
        },
        [
          _c("span", { staticClass: "mr-2 pc-toggle-label" }, [
            _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pc-toggle-inner" }, [
            _c(
              "div",
              {
                staticClass: "pc-toggle-dot",
                class: { disabled: _vm.disabled },
              },
              [_c("pc-icon", { attrs: { name: "check" } })],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.validationMessage
        ? _c(
            "p",
            { staticClass: "pc-toggle-error-message" },
            [
              _c("pc-icon", {
                staticClass: "mr-1 w-5 h-5",
                attrs: { name: "error" },
              }),
              _vm._v("\n    " + _vm._s(_vm.validationMessage) + "\n  "),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }