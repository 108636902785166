var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "pc-tag",
      class: [
        "pc-tag--" + _vm.type,
        _vm.small ? "pc-tag--small" : "pc-tag--regular",
        _vm.typeClasses,
      ],
      attrs: { "data-theme": _vm.themeName },
    },
    [
      _vm.$slots.icon
        ? _c(
            "span",
            {
              staticClass:
                "flex items-center justify-center overflow-hidden mr-2 w-4 h-4",
            },
            [_vm._t("icon")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }