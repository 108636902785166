<template>
  <link-helper
    class="pc-left-side-navigation-item"
    :href="href"
    v-on="$listeners"
  >
    <div class="flex items-center w-full justify-between z-10 relative">
      <span><slot></slot></span>
      <span v-if="$slots.icon" class="ml-auto">
        <slot name="icon"></slot>
      </span>
    </div>
  </link-helper>
</template>

<script>
import { LinkHelper } from '../Link'
export default {
  components: {
    LinkHelper
  },
  props: {
    href: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.pc-left-side-navigation-item {
  @apply flex items-center justify-between
  px-3 py-4 relative text-content-subdued cursor-pointer
  transition-colors duration-200 ease-in-out;

  @screen laptop {
    @apply font-bold;
  }
}

.pc-left-side-navigation-item:after {
  content: '';
  width: 4px;
  border-radius: 0 2px 2px 0;
  background-color: var(--dt-color-content-interactive-brand);
  @apply absolute inset-0 -ml-2 transition-opacity duration-200 ease-in-out opacity-0;
}

.pc-left-side-navigation-item.active {
  @apply text-content-default font-bold;
}

.pc-left-side-navigation-item.active:after {
  @apply opacity-100;
}

.pc-left-side-navigation-item:before {
  @apply absolute inset-0 bg-transparent transition-colors duration-200;
  content: '';
  border-radius: 2px;
}
.pc-left-side-navigation-item:hover:before {
  background-color: var(--dt-pc-color-backgound-default-mix-hover-01);
}

.pc-left-side-navigation-item:active:before {
  background-color: var(--dt-pc-color-backgound-default-mix-active-01);
}
</style>
