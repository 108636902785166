import Toasts from './Toasts.vue'
import { eventBus } from './events'

const Notify = {
  install(Vue, initialParams = {}) {
    if (this.installed) {
      return
    }

    this.installed = true

    Vue.component('pc-toast', Toasts)

    const notify = (params) => {
      eventBus.emit('add', { ...initialParams, ...params })
    }

    Vue.prototype.$notify = notify
    Vue.notify = notify
  }
}

export default Notify
