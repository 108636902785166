var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M20.954,7.527 L11.294,17.187 C11.0945805,17.3868689 10.8238397,17.4991919 10.5415,17.4991919 C10.2591603,17.4991919 9.98841951,17.3868689 9.789,17.187 L5.455,12.853 C5.04207384,12.438254 5.04207384,11.767746 5.455,11.353 L5.455,11.353 C5.65441951,11.1531311 5.92516028,11.0408081 6.2075,11.0408081 C6.48983972,11.0408081 6.76058049,11.1531311 6.96,11.353 L10.541,14.934 L19.782,5.692 C16.422196,1.48985508 10.3628357,0.648802396 5.9855606,3.77702351 C1.60828547,6.90524462 0.441576992,12.9104097 3.32910479,17.450067 C6.21663258,21.9897242 12.1501135,23.4786907 16.8389319,20.8402656 C21.5277503,18.2018406 23.3346383,12.3573242 20.953,7.533 L20.954,7.527 Z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }