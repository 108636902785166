var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M14.7073 6.70751V6.70751C14.8956 6.89514 15.0014 7.15002 15.0014 7.41584C15.0014 7.68166 14.8956 7.93654 14.7073 8.12417L10.8331 12L14.7106 15.8775C14.8989 16.0651 15.0047 16.32 15.0047 16.5858C15.0047 16.8517 14.8989 17.1065 14.7106 17.2942V17.2942C14.523 17.4825 14.2681 17.5883 14.0023 17.5883C13.7365 17.5883 13.4816 17.4825 13.294 17.2942L8.71063 12.7108C8.52234 12.5232 8.4165 12.2683 8.4165 12.0025C8.4165 11.7367 8.52234 11.4818 8.71063 11.2942L13.294 6.71084C13.6835 6.31998 14.316 6.31849 14.7073 6.70751Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }