var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "transition transform duration-300 ease-out",
        "enter-class": "opacity-0",
        "enter-to-class": "opacity-100",
        "leave-active-class": "transition transform duration-300 ease-out",
        "leave-class": "opacity-100",
        "leave-to-class": "opacity-0",
      },
    },
    [
      _vm.value
        ? _c(
            "div",
            {
              staticClass:
                "pc-modal fixed z-50 inset-0 flex items-center justify-center",
              attrs: { "data-theme": _vm.themeName, tabindex: "-1" },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.handleEscapeKey($event)
                },
              },
            },
            [
              _c("div", {
                staticClass: "pc-modal-overlay absolute inset-0",
                on: { click: _vm.handleBackdropClick },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "modal",
                  staticClass:
                    "\n        pc-modal-wrapper\n        absolute\n        overflow-y-auto\n        sm:h-auto\n        md:w-5/6\n        lg:w-3/6\n      ",
                  class: _vm.wrapperClasses,
                },
                [
                  _c(
                    "div",
                    { staticClass: "pc-modal-title relative" },
                    [
                      _vm._t("title"),
                      _vm._v(" "),
                      _vm.closable
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "pc-modal-close absolute right-0 top-0 cursor-pointer",
                              on: { click: _vm.close },
                            },
                            [
                              _c("pc-icon", {
                                staticClass: "w-6 h-6 fill-current",
                                attrs: { name: "close" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pc-modal-body relative" },
                    [_vm._t("content", [_c("p", [_vm._v("Default content")])])],
                    2
                  ),
                  _vm._v(" "),
                  _vm.$slots.footer
                    ? _c(
                        "div",
                        { staticClass: "pc-modal-footer relative" },
                        [_vm._t("footer")],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }