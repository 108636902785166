var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "transition",
    {
      attrs: {
        mode: "out-in",
        "enter-class": "opacity-0",
        "enter-active-class": "opacity-transition duration-300 ease",
        "leave-active-class":
          "opacity-transition duration-300 ease absolute opacity-0 -left-9999",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }