var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: "sox9h1fdwa",
            d: "M6.85 10.646l4.784 4.784c.202.202.53.202.732 0l4.784-4.784c.179-.178.232-.446.136-.678-.096-.233-.323-.385-.575-.385H7.289c-.252 0-.479.152-.575.385-.096.232-.043.5.136.678z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("g", [
          _c("g", [
            _c("g", [
              _c(
                "g",
                {
                  attrs: {
                    transform:
                      "translate(-264 -234) translate(16 194) translate(0 28) translate(248 12)",
                  },
                },
                [
                  _c("use", {
                    attrs: { fill: "#5E6B77", "xlink:href": "#sox9h1fdwa" },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }