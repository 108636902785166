export default function emitter(all) {
  all = all || new Map()
  return {
    all,
    on(type, handler) {
      const handlers = all.get(type)
      if (handlers) {
        handlers.push(handler)
        return
      }
      all.set(type, [handler])
    },
    emit(type, evt) {
      let handlers = all.get(type)
      if (!handlers) return
      handlers.slice().map((handler) => {
        handler(evt)
      })
    }
  }
}

export const eventBus = emitter()
