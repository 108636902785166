<template>
  <div
    class="flex items-center justify-center md:justify-between flex-col lg:flex-row"
  >
    <span class="leading-normal text-grey-50"
      ><span class="font-bold">{{ startValue }} - {{ endValue }}</span>
      {{ of }} <span class="font-bold">{{ total }}</span> {{ items }}</span
    >
    <template v-if="pageCount > 1">
      <div class="flex mt-4 px-2 w-full justify-between items-center md:hidden">
        <span @click="decrease"
          ><svg
            class="w-6 h-6 text-grey-50"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path></svg
        ></span>
        <div class="flex items-center space-x-1">
          <span class="text-grey-50 font-bold">Page</span>
          <div class="flex items-center relative">
            <select
              class="select border border-grey-40 box-border appearance-none outline-none bg-transparent leading-normal h-10 pl-4 pr-8"
              v-model="page"
              name="page"
              id=""
            >
              <option v-for="page in pageCount" :key="page" :value="page">
                {{ page }} {{ of }} {{ pageCount }}
              </option>
            </select>
            <svg
              class="absolute right-0 mr-4 w-3 h-2 pointer-events-none text-grey-50"
              fill="currentColor"
              viewBox="0 0 12 6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                transform="rotate(90 5.836 2.983)"
                d="M3.203-1.798c-.13.128-.202.303-.202.486 0 .182.073.357.202.486L7.01 2.983 3.2 6.793C3.074 6.92 3 7.095 3 7.277s.073.357.202.486c.128.13.303.202.486.202.182 0 .357-.073.486-.202L8.47 3.47c.13-.13.202-.304.202-.486s-.073-.357-.202-.486L4.175-1.798C4.046-1.928 3.87-2 3.689-2c-.183 0-.357.073-.486.202z"
              />
            </svg>
          </div>
        </div>
        <span @click="increase"
          ><svg
            class="w-6 h-6 text-grey-50"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            ></path></svg
        ></span>
      </div>
      <div class="items-center hidden md:flex">
        <button
          class="rounded-full focus:outline-none focus:shadow-outline w-8 h-8 inline-flex items-center justify-center hover:bg-grey-30"
          @click="goFirst"
          :tabindex="calculateTabindex(1)"
        >
          <svg
            class="w-4 h-4 text-grey-50 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            ></path>
          </svg>
        </button>
        <paginate
          v-model="page"
          :page-count="pageCount"
          prev-text='<svg class="w-4 h-4 text-grey-50" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>'
          next-text='<svg class="w-4 h-4 text-grey-50" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>'
          last-button-text="olololo"
          page-class="rounded-full w-8 h-8 inline-flex text-sm font-bold leading-tight hover:bg-grey-30 cursor-pointer"
          prev-class="w-8 h-8"
          next-class="w-8 h-8"
          page-link-class="focus:outline-none focus:shadow-outline rounded-full flex items-center justify-center w-full"
          next-link-class="w-full h-full rounded-full inline-flex items-center justify-center hover:bg-grey-30 focus:outline-none focus:shadow-outline"
          prev-link-class="w-full h-full rounded-full inline-flex items-center justify-center hover:bg-grey-30 focus:outline-none focus:shadow-outline"
          active-class="text-blue-100 bg-blue-50 hover:bg-blue-50"
          :container-class="'flex space-x-1'"
        >
        </paginate>
        <button
          class="rounded-full focus:outline-none focus:shadow-outline w-8 h-8 inline-flex items-center justify-center hover:bg-grey-30"
          @click="goLast"
          :tabindex="calculateTabindex(pageCount)"
        >
          <svg
            class="w-4 h-4 text-grey-50 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            ></path>
          </svg>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  data() {
    return {
      page: 1
    }
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      default: 10
    },
    of: {
      type: String,
      default: 'of'
    },
    items: {
      type: String,
      default: 'items'
    }
  },
  components: {
    Paginate
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.step)
    },
    startValue() {
      return (this.page - 1) * this.step + 1
    },
    endValue() {
      const end = this.page * this.step
      return end >= this.total ? this.total : end
    }
  },
  watch: {
    page(newValue) {
      this.$emit('change', newValue)
    }
  },
  methods: {
    goLast() {
      this.page = this.pageCount
    },
    goFirst() {
      this.page = 1
    },
    increase() {
      if (this.page === this.pageCount) return
      this.page++
    },
    decrease() {
      if (this.page === 1) return
      this.page--
    },
    calculateTabindex(page) {
      if (this.page === page) return -1
      return 0
    }
  }
}
</script>

<style scoped>
.select {
  min-width: 110px;
}
</style>
