<template>
  <div
    class="
      container
      fixed
      bottom-0
      left-0
      right-0
      m-auto
      mb-3
      z-10
      flex flex-col
      justify-end
      h-0
    "
    data-cy="pc-toast-container"
  >
    <transition-group
      tag="div"
      class="pc-toast-wrapper"
      enter-active-class="transition transform duration-300 ease-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition transform duration-300 ease-out"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-for="toast in toastsList"
        :key="toast.id"
        :data-theme="themeName"
        class="pc-toast"
        :class="[
          `pc-toast--${toast.type}`,
          { 'pc-toast--compact': toast.compact }
        ]"
        :data-cy="`pc-toast pc-toast-${toast.type}`"
      >
        <div class="flex" :class="{ 'flex-grow': toast.compact }">
          <div
            v-if="!toast.compact && getIconName(toast.iconName, toast.type)"
            class="pc-toast-icon"
          >
            <pc-icon
              :name="getIconName(toast.iconName, toast.type)"
              class="w-6 h-6"
            />
          </div>
          <slot name="message" :item="toast">
            <p v-html="toast.message"></p>
          </slot>
        </div>
        <slot name="actions" :item="toast" :close="() => removeToast(toast.id)">
          <div
            v-if="toast.closeButton"
            class="text-right"
            :class="{ 'w-full mt-3': !toast.compact }"
          >
            <button class="pc-toast-close" @click="removeToast(toast.id)">
              {{ toast.closeButton }}
            </button>
          </div>
        </slot>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { TOAST_TYPES, DEFAULT_CONFIG } from './constants'
import { eventBus } from './events'
import PcIcon from '../../components/Icon'
import theme from '../../mixins/theme'

export default {
  name: 'PcToast',
  components: {
    PcIcon
  },
  mixins: [theme],
  props: {
    group: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    TOAST_TYPES,
    DEFAULT_CONFIG,
    toastsList: []
  }),
  mounted() {
    eventBus.on('add', this.addToast)
  },
  methods: {
    getIconName(iconName, type) {
      return iconName || this.TOAST_TYPES[type].iconName
    },
    addToast(config) {
      const id = Symbol('id')
      const item = {
        id,
        ...this.DEFAULT_CONFIG,
        ...config
      }

      item.group = item.group || ''

      if (this.group !== item.group) {
        return
      }

      if (item.clean) {
        this.clean()
        return
      }

      if (!this.TOAST_TYPES[item.type]) {
        item.type = this.DEFAULT_CONFIG.type
        console.error(`${config.type} is an invalid toast type`)
      }
      this.toastsList.push(item)
      if (item.autoclose) {
        this.removeAfterTimer(item)
      }
    },
    removeAfterTimer(item) {
      setTimeout(() => {
        this.removeToast(item.id)
      }, item.autocloseTimeout)
    },
    removeToast(id) {
      const index = this.toastsList.findIndex((item) => item.id === id)
      if (index === -1) {
        return
      }
      this.toastsList.splice(index, 1)
    },
    clean() {
      this.toastsList = []
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';

.pc-toast-wrapper {
  width: 18.75rem;
}

.pc-toast[data-theme='default'] {
  @apply flex flex-grow flex-wrap
  py-4
  pl-3
  pr-4
  mb-2
  text-sm
  border-l-4
  rounded
  bg-grey-60
  text-white;

  .pc-toast-icon {
    @apply mr-4 w-6 h-6 flex items-center justify-center;
  }

  .pc-toast-close {
    @apply font-bold;
  }

  &.pc-toast {
    &--default {
      @apply border-grey-60;

      .pc-toast-icon {
        @apply text-grey-60;
      }
    }
    &--success {
      @apply border-green-30;

      .pc-toast-icon {
        @apply text-green-30;
      }
    }
    &--info {
      @apply border-blue-30;

      .pc-toast-icon {
        @apply text-blue-30;
      }
    }
    &--warning {
      @apply border-yellow-100;

      .pc-toast-icon {
        @apply text-yellow-100;
      }
    }
    &--error {
      @apply border-red-100;

      .pc-toast-icon {
        @apply text-red-100;
      }
    }
  }
}
.pc-toast[data-theme='icing'] {
  @apply flex flex-grow flex-wrap mb-2 border-l-4 rounded-radius-b
  pl-3.5 pr-4 py-4
  bg-container-inverse text-content-inverse;
  @mixin body-s;

  &.pc-toast--compact {
    @apply py-3.5 pr-4 pl-3;
  }

  .pc-toast-icon {
    @apply mr-4 w-6 h-6 flex items-center justify-center;
  }

  .pc-toast-close {
    @mixin interactive-xs;
  }

  &.pc-toast {
    &--default,
    &--neutral {
      @apply border-transparent;

      .pc-toast-icon {
        @apply text-content-inverse;
      }
    }
    &--success,
    &--positive {
      @apply border-support-positive-inverse;

      .pc-toast-icon {
        @apply text-support-positive-inverse;
      }
    }
    &--info {
      @apply border-support-info-inverse;

      .pc-toast-icon {
        @apply text-support-info-inverse;
      }
    }
    &--warning {
      @apply border-support-warning;

      .pc-toast-icon {
        @apply text-support-warning;
      }
    }
    &--error {
      @apply border-support-error;

      .pc-toast-icon {
        @apply text-support-error;
      }
    }
  }
}
</style>
