var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "link-helper",
    _vm._g(
      { staticClass: "menu-card", attrs: { href: _vm.href } },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "flex flex-row items-center" },
        [
          _c("div", { staticClass: "flex items-start flex-wrap flex-grow" }, [
            _vm.$slots.icon || _vm.cardIcon
              ? _c(
                  "div",
                  {
                    staticClass:
                      "\n          menu-card-icon\n          flex\n          items-center\n          justify-center\n          w-6\n          h-6\n          laptop:w-8 laptop:h-8\n          mr-4\n        ",
                  },
                  [
                    _vm._t("icon", [
                      _c("icon", { attrs: { name: _vm.cardIcon } }),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "flex-1" }, [
              _vm.$slots.cardHeading || _vm.cardHeading
                ? _c(
                    "div",
                    { staticClass: "inline-flex items-center mr-2" },
                    [
                      _vm._t("cardHeading", [
                        _c("h3", { staticClass: "pc-heading-s" }, [
                          _vm._v(_vm._s(_vm.cardHeading)),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots.tags
                ? _c(
                    "div",
                    { staticClass: "menu-card-tags inline-block" },
                    [_vm._t("tags")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots.default || _vm.cardContent
                ? _c(
                    "p",
                    { staticClass: "mt-1" },
                    [_vm._t("default", [_vm._v(_vm._s(_vm.cardContent))])],
                    2
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("pc-icon", {
            staticClass: "w-6 h-6 flex-shrink-0",
            attrs: { name: "chevronRight" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }