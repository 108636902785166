var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pb-6 px-4 laptop:px-6",
      class: [{ "pt-6 tablet:pt-10": _vm.hasTopOffset }],
    },
    [
      _c("div", { staticClass: "container" }, [
        _vm.hasHeading || _vm.hasDescription
          ? _c(
              "div",
              { staticClass: "mb-6" },
              [
                _vm._t("heading", [
                  _vm.heading
                    ? _c(
                        "h1",
                        {
                          staticClass: "pc-heading-l",
                          attrs: { "data-test": "page-title" },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.heading) + "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.hasDescription
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _vm._t("description", [
                          _vm._v(_vm._s(_vm.description)),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasDivider
          ? _c("hr", { staticClass: "pc-divider-default mb-6" })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "flex flex-col laptop:flex-row" }, [
          _vm.$slots.sidebar
            ? _c("div", { staticClass: "pc-sidebar" }, [_vm._t("sidebar")], 2)
            : _vm._e(),
          _vm._v(" "),
          _vm.$slots.default
            ? _c("div", { staticClass: "flex-1" }, [_vm._t("default")], 2)
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }