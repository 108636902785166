var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "link-helper",
    _vm._g(
      {
        staticClass: "pc-left-side-navigation-item",
        attrs: { href: _vm.href },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          staticClass: "flex items-center w-full justify-between z-10 relative",
        },
        [
          _c("span", [_vm._t("default")], 2),
          _vm._v(" "),
          _vm.$slots.icon
            ? _c("span", { staticClass: "ml-auto" }, [_vm._t("icon")], 2)
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }