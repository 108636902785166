import uk from './uk'
import au from './au'
import nz from './nz'
import es from './es'
import ie from './ie'
import it from './it'

const tenantConfigs = {
  uk,
  au,
  nz,
  es,
  ie,
  it
}

export default tenantConfigs
