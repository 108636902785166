var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "pc-chip",
      class: [
        "pc-chip--" + _vm.type,
        { "pc-chip--disabled": _vm.disabled },
        { "pc-chip--checked": _vm.isChecked },
      ],
      attrs: { "data-theme": _vm.themeName, tabindex: "0" },
    },
    [
      _c("input", {
        staticClass: "opacity-0 w-0 h-0",
        attrs: { disabled: _vm.disabled, type: "checkbox" },
        domProps: { checked: _vm.isChecked, value: _vm.value },
        on: { change: _vm.onCheckboxChange },
      }),
      _vm._v(" "),
      _vm.hasCheckIcon && _vm.isChecked
        ? _c("pc-icon", {
            staticClass: "mr-2 inline-block",
            attrs: { name: "check" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }