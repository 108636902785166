var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M18.5293 3.60002L20.3463 5.41602C21.1183 6.18902 21.1153 7.44902 20.3393 8.22502L18.5513 10.012L13.9313 5.39202L15.7193 3.60502C16.0933 3.23002 16.5913 3.02202 17.1203 3.02002C17.6973 3.03402 18.1533 3.22402 18.5293 3.60002ZM4.12783 15.3875C4.17283 15.2175 4.26183 15.0625 4.38683 14.9375L12.5068 6.81752L17.1268 11.4375L9.00683 19.5575C8.88083 19.6835 8.72383 19.7735 8.55183 19.8185L4.19183 20.9555C4.10883 20.9765 4.02383 20.9875 3.93983 20.9875C3.67683 20.9875 3.42083 20.8835 3.23083 20.6925C2.97983 20.4405 2.88183 20.0745 2.97283 19.7305L4.12783 15.3875Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }