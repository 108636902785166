var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.getTag,
    _vm._g(
      {
        tag: "component",
        staticClass: "pc-one-list-item",
        class: [
          { "pc-one-list-item--compact": _vm.compact },
          { "pc-one-list-item--divider": _vm.divider },
        ],
        attrs: { href: _vm.href },
      },
      _vm.$listeners
    ),
    [
      _vm.$slots.leftContent
        ? _c(
            "div",
            { staticClass: "pc-one-list-item-content--left" },
            [_vm._t("leftContent")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "pc-one-list-item-content" }, [
        _c(
          "div",
          [
            _vm._t("default"),
            _vm._v(" "),
            _vm.$slots.secondaryText
              ? _c("p", [_vm._t("secondaryText")], 2)
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _vm.$slots.rightContent
          ? _c(
              "div",
              { staticClass: "ml-auto pl-4" },
              [_vm._t("rightContent")],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }