export const DROPDOWN_POSITIONS = {
  bottom: {
    transition: 'SlideYUpTransition',
    dropdown: 'top-full mt-2'
  },
  top: {
    transition: 'SlideYDownTransition',
    dropdown: 'bottom-full mb-2'
  }
}
