var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("g", { attrs: { "fill-rule": "evenodd" } }, [
        _c("g", [
          _c("g", [
            _c("g", [
              _c("path", {
                attrs: {
                  d: "M7.993 1.333c-3.68 0-6.66 2.987-6.66 6.667 0 3.68 2.98 6.667 6.66 6.667 3.687 0 6.674-2.987 6.674-6.667 0-3.68-2.987-6.667-6.674-6.667zm.007 12c-2.947 0-5.333-2.386-5.333-5.333S5.053 2.667 8 2.667 13.333 5.053 13.333 8 10.947 13.333 8 13.333zm-.187-8.666c-.266 0-.48.213-.48.48v3.146c0 .234.12.454.327.574l2.767 1.66c.226.133.52.066.653-.16.14-.227.067-.527-.167-.66l-2.58-1.534V5.147c0-.267-.213-.48-.48-.48h-.04z",
                  transform:
                    "translate(-498 -632) translate(490 628) translate(8 4)",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }