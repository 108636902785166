<template>
  <div class="pc-left-side-navigation">
    <nav class="flex flex-col pl-2">
      <slot></slot>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

