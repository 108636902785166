<template>
  <div :data-theme="themeName" class="pc-dropdown">
    <div v-if="label" class="pc-dropdown-label">{{ label }}</div>
    <div
      class="pc-dropdown-inner"
      :class="{ disabled }"
      :tabindex="getTabindexValue"
      @click="toggleSelect"
      @keyup.enter="handleSelectKeyup"
      @keyup.space="handleSelectKeyup"
      @keyup.down="onArrowDown"
      @keyup.up="onArrowUp"
      @focusout="isOpen = false"
    >
      <div
        class="pc-dropdown-picker"
        :class="{
          invalid: !isValid,
          disabled
        }"
      >
        <p v-if="selectedValue[itemText]" class="pc-dropdown-value">
          {{ selectedValue[itemText] }}
        </p>
        <p v-else class="pc-dropdown-placeholder">{{ placeholder }}</p>
        <pc-icon
          :name="iconName"
          class="transition duration-200 linear w-5 h-5"
          :class="{ 'transform rotate-180': isOpen }"
        />
      </div>
      <component :is="positionConfig.transition" appear>
        <ul
          v-show="isOpen"
          ref="dropdown"
          class="pc-dropdown-list scrollbar"
          :class="positionConfig.dropdown"
          style="max-height: 15rem"
        >
          <li
            v-for="(item, i) in items"
            :key="i"
            class="pc-dropdown-list-item"
            :class="{
              selected: item[itemValue] === value,
              'bg-grey-10': i === arrowCounter
            }"
            @click="selectItem(item)"
            @mouseenter="arrowCounter = -1"
          >
            {{ item[itemText] }}
          </li>
        </ul>
      </component>
    </div>
    <p v-if="validationMessage" class="pc-dropdown-error-message">
      <pc-icon class="mr-1 w-5 h-5" name="error" />
      {{ validationMessage }}
    </p>

    <!-- IF recursive  -->
    <pc-select
      v-if="isAvailableRecursive"
      v-model="subitemValue"
      :items="getSubitems"
      :placeholder="placeholder"
      is-recursive
      class="mt-3"
      @bus="bus"
    ></pc-select>
  </div>
</template>

<script>
import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions'
import PcIcon from '../Icon'
import theme from '../../mixins/theme'
import clickOutside from '../../mixins/clickOutside'
import { DROPDOWN_POSITIONS } from './constants'

export default {
  name: 'PcSelect',
  components: {
    PcIcon,
    SlideYUpTransition,
    SlideYDownTransition
  },
  mixins: [clickOutside, theme],
  props: {
    value: {
      type: [String, Number, Object],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    validationMessage: {
      type: String,
      default: ''
    },
    isRecursive: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    arrowCounter: -1,
    subitemValue: '',
    positionConfig: DROPDOWN_POSITIONS.bottom
  }),
  computed: {
    iconName() {
      return this.themeConfig.isIcing ? 'chevronDown' : 'caretDown'
    },
    getTabindexValue() {
      return this.disabled ? -1 : 0
    },
    selectedValue: {
      get() {
        const i = this.items.findIndex(
          (item) => item[this.itemValue] === this.value
        )
        const item = this.items[i]
        return item || {}
      },
      set(item) {
        this.subitemValue = ''
        this.$emit('input', item[this.itemValue])
      }
    },
    isAvailableRecursive() {
      return this.isRecursive && this.getSubitems.length !== 0
    },
    getSubitems() {
      if (!this.value) return []
      return this.selectedValue.subTypes ?? []
    }
  },
  watch: {
    subitemValue() {
      if (!this.subitemValue) return
      this.bus(this.subitemValue)
    },
    isOpen(value) {
      if (value) {
        window.addEventListener('keydown', this.preventArrowKeysActions)
        this.$nextTick(() => {
          this.calculatePosition()
        })
      } else {
        window.removeEventListener('keydown', this.preventArrowKeysActions)
      }
    }
  },
  methods: {
    toggleSelect() {
      if (this.disabled) return
      this.isOpen = !this.isOpen
      this.arrowCounter = -1
    },
    selectItem(item) {
      if (this.value === item.value) return
      this.selectedValue = item
    },
    handleSelectKeyup() {
      if (this.isOpen && this.arrowCounter !== -1) {
        const selected = this.items[this.arrowCounter]
        this.selectItem(selected)
      }
      this.toggleSelect()
    },
    onArrowDown() {
      if (!this.isOpen) return
      this.arrowCounter =
        this.arrowCounter < this.items.length - 1
          ? (this.arrowCounter += 1)
          : (this.arrowCounter = 0)
    },
    onArrowUp() {
      if (!this.isOpen) return
      this.arrowCounter =
        this.arrowCounter > 0
          ? (this.arrowCounter -= 1)
          : (this.arrowCounter = this.items.length - 1)
    },
    preventArrowKeysActions(e) {
      if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault()
      }
    },
    bus(data) {
      this.$emit('bus', data)
    },
    calculatePosition() {
      const bottomSpace =
        this.$el.getBoundingClientRect().top +
        this.$el.getBoundingClientRect().height +
        this.$refs.dropdown?.getBoundingClientRect().height
      if (bottomSpace > window.innerHeight) {
        this.positionConfig = DROPDOWN_POSITIONS.top
      } else {
        this.positionConfig = DROPDOWN_POSITIONS.bottom
      }
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';
@import '../../assets/themes/icing/css/mixins/form-input.pcss';
.pc-dropdown[data-theme='default'] {
  .pc-dropdown-inner {
    @apply relative;

    &:focus {
      @apply outline-none;
    }

    &:not(.disabled):focus {
      @apply shadow-outline;
    }
  }

  .pc-dropdown-picker {
    @apply flex
    justify-between
    py-3
    px-4
    relative
    rounded-sm
    border
    leading-snug
    cursor-pointer
    border-grey-40
    bg-white;

    &.invalid {
      @apply border-red-100;
    }

    &.disabled {
      @apply bg-grey-30;
    }
  }

  .pc-dropdown-placeholder {
    @apply text-grey-50;
  }

  .pc-dropdown-list {
    @apply absolute
    block
    w-full
    shadow-dropdown
    rounded-sm
    mt-2
    z-20
    overflow-y-auto;
  }

  .pc-dropdown-list-item {
    @apply bg-white
    py-3
    px-4
    leading-snug
    cursor-pointer;

    &:hover {
      @apply bg-grey-10;
    }

    &.selected {
      @apply font-bold;
    }
  }

  .pc-dropdown-error-message {
    @apply mt-1 text-sm text-red-100 flex;
  }
}
.pc-dropdown[data-theme='icing'] {
  .pc-dropdown-inner {
    @apply relative text-content-subdued;

    &:not(.disabled):hover .pc-dropdown-picker,
    &:not(.disabled):active .pc-dropdown-picker {
      @mixin useInputHover;
    }

    &:not(.disabled):focus .pc-dropdown-picker {
      @apply outline-none shadow-outline-focus;
    }
  }

  .pc-dropdown-picker {
    @apply flex
    justify-between
    items-center
    py-3
    pl-4
    pr-5
    relative
    leading-snug
    cursor-pointer
    bg-container-default
    duration-200 transition-colors
    border
    border-interactive-form
    rounded-radius-c;

    &.invalid {
      @apply border-support-error;
    }

    &.disabled {
      @apply bg-disabled-01 border-disabled-01;

      .pc-dropdown-value,
      .pc-dropdown-placeholder {
        @apply text-content-disabled;
      }

      .pc-dropdown-label {
        @apply text-content-disabled;
      }
    }
  }

  .pc-dropdown-list {
    @apply absolute
    block
    bg-container-default
    w-full
    shadow-dropdown
    rounded-sm
    mt-2
    z-20
    overflow-y-auto;
  }

  .pc-dropdown-list-item {
    @apply py-3
    px-4
    leading-snug
    cursor-pointer;

    &:hover {
      @mixin useInputHover;
    }

    &.selected {
      @apply font-bold;
    }
  }

  .pc-dropdown-error-message {
    @apply my-1 text-content-error flex items-center;
    @mixin body-s;
  }

  .pc-dropdown-label {
    @mixin body-s;
    @apply font-bold mb-1;
  }
}

.scrollbar {
  scrollbar-color: #9d9d9d transparent;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #9d9d9d;
  border-radius: 25px;
}
</style>
