<template>
  <component
    :is="getTag"
    class="pc-link-helper"
    :active-class="activeClass"
    v-bind="getProps"
    v-on="$listeners"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: ''
    },
    activeClass: {
      type: String,
      default: 'active'
    }
  },
  computed: {
    isExternalLink() {
      return this.href?.match(/^(http(s)?):\/\//)
    },
    getTag() {
      if (this.href) {
        return this.isExternalLink ? 'a' : 'router-link'
      }
      return 'div'
    },
    getProps() {
      if (this.href) {
        return this.isExternalLink
          ? { href: this.href, target: '_blank' }
          : { to: this.href }
      }
      return {}
    }
  }
}
</script>
