import { BREAKPOINTS } from '../constants'
import theme from './theme'

export default {
  mixins: [theme],
  data() {
    return {
      windowInnerWidth: null
    }
  },
  computed: {
    /**
     * Represents breakpoint for the current inner windows width (e.g. 'xs', 'sm', 'md', 'lg', 'xl')
     */
    currentBreakpoint() {
      return Object.keys(BREAKPOINTS).find((current, index, types) => {
        const next = types[index + 1]

        if (!next) {
          return this.windowInnerWidth >= BREAKPOINTS[current]
        }

        return (
          this.windowInnerWidth >= BREAKPOINTS[current] &&
          this.windowInnerWidth < BREAKPOINTS[next]
        )
      })
    },
    /**
     * @deprecated `isMobile` property is deprecated, please use `currentBreakpoint` property or `breakpointUp` function
     */
    isMobile() {
      const breakPoint = this.themeConfig.isIcing ? 'tablet' : 'sm'
      return this.windowInnerWidth < BREAKPOINTS[breakPoint]
    },
    isWideScreen() {
      return this.windowInnerWidth > BREAKPOINTS['laptop']
    },
    isNarrowScreen() {
      return !this.isWideScreen
    }
  },
  created() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowInnerWidth = window.innerWidth
    },
    /**
     * Calculates if the current inner windows width is not less than min width of the provided breakpoint
     * @param {string} breakpoint - the target breakpoint (e.g. 'xs', 'sm', 'md', 'lg', 'xl')
     * @return {boolean} Returns `false` if the current inner window width is less than min width of the provided breakpoint, otherwise returns `true`
     * @throws Throws error if provided breakpoint is not supported
     */
    breakpointUp(breakpoint) {
      if (breakpoint in BREAKPOINTS) {
        const minWidth = BREAKPOINTS[breakpoint]
        return this.windowInnerWidth >= minWidth
      }

      throw new Error(`Breakpoint of type '${breakpoint}' is not supported`)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
