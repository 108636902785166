<template>
  <component :is="iconComponent" role="img" class="fill-current" />
</template>

<script>
const icons = {
  error: require('./icons/IconError.vue').default,
  info: require('./icons/IconInfo.vue').default,
  success: require('./icons/IconSuccess.vue').default,
  warning: require('./icons/IconWarning.vue').default,
  loading: require('./icons/IconLoading.vue').default,
  sync: require('./icons/IconSync.vue').default,
  clock: require('./icons/IconClock.vue').default,
  delete: require('./icons/IconDelete.vue').default,
  caretDown: require('./icons/IconCaretDown.vue').default,
  check: require('./icons/IconCheck.vue').default,
  checkFilled: require('./icons/IconCheckFilled.vue').default,
  arrowDown: require('./icons/IconArrowDown.vue').default,
  arrowTop: require('./icons/IconArrowTop.vue').default,
  plus: require('./icons/IconPlus.vue').default,
  minus: require('./icons/IconMinus.vue').default,
  pencil: require('./icons/IconPencil.vue').default,
  close: require('./icons/IconClose.vue').default,
  back: require('./icons/IconBack.vue').default,
  moreVertical: require('./icons/IconMoreVertical.vue').default,
  moon: require('./icons/IconMoon.vue').default,
  chevronRight: require('./icons/IconChevronRight.vue').default,
  chevronDown: require('./icons/IconChevronDown.vue').default,
  arrowLongLeft: require('./icons/IconArrowLongLeft.vue').default
}

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    }
  },

  computed: {
    iconComponent() {
      return icons[this.name]
    }
  }
}
</script>
