<template>
  <div
    :data-theme="themeName"
    class="pc-checkbox"
    :class="[
      {
        'pc-checkbox--error': validationMessage
      }
    ]"
  >
    <input
      :id="id"
      class="pc-checkbox-field"
      type="checkbox"
      :value="value"
      :checked="isChecked"
      :disabled="disabled"
      @change="onCheckboxChange"
    />
    <label :for="id" class="pc-checkbox-label">
      <div class="pc-dummy-check">
        <pc-icon :name="iconName" class="pc-check-icon" />
      </div>
      <slot><span v-html="label"></span></slot>
    </label>
    <p v-if="validationMessage" class="pc-checkbox-error-message">
      <pc-icon class="mr-1 w-5 h-5" name="error" />
      {{ validationMessage }}
    </p>
  </div>
</template>

<script>
import PcIcon from '../Icon'
import theme from '../../mixins/theme'
import checkbox from '../../mixins/checkbox'

export default {
  name: 'PcCheckbox',
  components: {
    PcIcon
  },
  mixins: [checkbox, theme],
  props: {
    partial: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconName() {
      return this.partial ? 'minus' : 'check'
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';
@import '../../assets/themes/icing/css/mixins/form-input.pcss';

.pc-checkbox[data-theme='default'] {
  .pc-checkbox-label .pc-dummy-check {
    @apply border rounded-sm border-grey-30 absolute left-0 bg-white;
    width: 18px;
    height: 18px;
    top: 3px;
  }

  .pc-checkbox-field {
    @apply absolute -left-9999;
  }

  .pc-checkbox-label {
    @apply relative pl-8 leading-normal inline-flex cursor-pointer;
  }

  .pc-checkbox-field:checked + .pc-checkbox-label .pc-dummy-check {
    @apply bg-blue-100;
    @apply text-white;
  }

  .pc-checkbox-field:focus + .pc-checkbox-label .pc-dummy-check {
    @apply shadow-outline;
  }

  .pc-checkbox-field:disabled:checked + .pc-checkbox-label .pc-dummy-check {
    @apply bg-grey-30;
    @apply text-grey-45;
  }

  .pc-check-icon {
    @apply w-4 h-4 fill-current opacity-0;
  }

  .pc-checkbox-field:checked + .pc-checkbox-label .pc-check-icon {
    @apply opacity-100;
  }

  .pc-checkbox-field:disabled + .pc-checkbox-label {
    @apply text-grey-45 cursor-not-allowed;

    .pc-dummy-check {
      @apply bg-grey-30;
    }
  }

  .pc-checkbox-error-message {
    @apply mt-1 text-sm text-red-100 flex;
  }
}

.pc-checkbox[data-theme='icing'] {
  @apply p-0.5;

  .pc-checkbox-field {
    @apply absolute -left-9999;
  }

  .pc-dummy-check {
    @apply flex items-center justify-center border rounded-sm border-interactive-form absolute left-0 duration-200 transition-all text-content-light;
    width: 22px;
    height: 22px;
    top: 1px;
  }

  .pc-checkbox-label {
    @apply relative pl-8 inline-flex cursor-pointer;
  }

  .pc-check-icon {
    @apply w-5 h-5 fill-current opacity-0 duration-200 transition-all;
  }

  .pc-checkbox-error-message {
    @apply mt-1 text-sm text-support-error flex items-center;
    @mixin body-s;
  }

  .pc-checkbox-field:not(:disabled):not(:checked) + .pc-checkbox-label {
    &:hover .pc-dummy-check {
      @mixin useInputHover;
    }

    &:active .pc-dummy-check {
      @mixin useInputActive;
    }
  }

  .pc-checkbox-field:focus + .pc-checkbox-label .pc-dummy-check {
    @apply shadow-reactive-focus-01;
  }

  .pc-checkbox-field:checked + .pc-checkbox-label .pc-check-icon {
    @apply opacity-100;
  }

  .pc-checkbox-field:not(:disabled):checked + .pc-checkbox-label {
    .pc-dummy-check {
      @apply bg-interactive-brand text-content-light border-interactive-brand;
    }

    &:hover .pc-dummy-check {
      background-color: var(--dt-pc-color-iteractive-brand-mix-hover-01);
      border-color: var(--dt-pc-color-iteractive-brand-mix-hover-01);
    }

    &:active .pc-dummy-check {
      background-color: var(--dt-pc-color-iteractive-brand-mix-active-01);
      border-color: var(--dt-pc-color-iteractive-brand-mix-active-01);
    }
  }

  .pc-checkbox-field:disabled + .pc-checkbox-label {
    @apply cursor-not-allowed;

    .pc-dummy-check {
      @apply bg-disabled-01 text-content-disabled border-color-default;
    }
  }

  &.pc-checkbox--error {
    .pc-checkbox-field:not(:checked) + .pc-checkbox-label .pc-dummy-check {
      @apply border-support-error;
    }

    .pc-checkbox-field:not(:disabled):checked + .pc-checkbox-label {
      &:hover .pc-dummy-check,
      &:active .pc-dummy-check,
      .pc-dummy-check {
        @apply bg-support-error text-content-light border-support-error;
      }
    }
  }
}
</style>
