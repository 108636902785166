<template>
  <div
    class="pb-6 px-4 laptop:px-6"
    :class="[{ 'pt-6 tablet:pt-10': hasTopOffset }]"
  >
    <!-- heading -->
    <div class="container">
      <div v-if="hasHeading || hasDescription" class="mb-6">
        <slot name="heading">
          <h1 v-if="heading" data-test="page-title" class="pc-heading-l">
            {{ heading }}
          </h1>
        </slot>
        <div v-if="hasDescription" class="mt-2">
          <slot name="description">{{ description }}</slot>
        </div>
      </div>

      <!-- divider -->
      <hr v-if="hasDivider" class="pc-divider-default mb-6" />

      <!-- content -->
      <div class="flex flex-col laptop:flex-row">
        <div v-if="$slots.sidebar" class="pc-sidebar">
          <slot name="sidebar"></slot>
        </div>
        <div v-if="$slots.default" class="flex-1">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    hasDivider: {
      type: Boolean,
      default: false
    },
    /**
     * When equals true, adds offset on the top of the component.
     * It is useful to have top offset when navigation on the page has no back button.
     */
    hasTopOffset: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasHeading() {
      return Boolean(this.$slots.heading || this.heading)
    },
    hasDescription() {
      return Boolean(this.$slots.description || this.description)
    }
  },
}
</script>

<style scoped>
.pc-sidebar {
  --sidebar-width: 296px;
  --sidebar-offset: 24px;

  @apply w-full;

  @screen laptop {
    max-width: var(--sidebar-width);
    margin-right: var(--sidebar-offset);
  }
}
</style>
