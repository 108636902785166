<template>
  <div
    :data-theme="themeName"
    class="pc-radio"
    :class="{ 'pc-radio--error': !isValid }"
  >
    <input
      :id="id"
      :value="value"
      :checked="isChecked"
      class="pc-radio-field"
      :class="{ checked: isChecked }"
      :disabled="disabled"
      type="radio"
      @change="$emit('change', $event.target.value)"
    />
    <label class="pc-radio-label" :for="id">
      <slot><span v-html="label"></span></slot>
    </label>
  </div>
</template>

<script>
import id from '../../helpers/randomId'
import theme from '../../mixins/theme'

export default {
  name: 'PcRadio',
  mixins: [theme],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: {
      default: ''
    },
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    id,
    isChecked() {
      return this.modelValue == this.value
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/form-input.pcss';
.pc-radio[data-theme='default'] {
  @apply inline-flex;

  .pc-radio-field {
    @apply flex-shrink-0 h-5 w-5 mr-2  rounded-full border border-grey-30 cursor-pointer appearance-none duration-200 transition-all;

    &:focus {
      @apply outline-none shadow-outline-button;
    }

    &.checked {
      @apply text-blue-100 border-transparent;
      background-color: currentColor;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
    }

    &:disabled {
      @apply bg-grey-30 border-grey-30 cursor-not-allowed;
    }

    &.checked:disabled {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23929FAA' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
    }
  }

  .pc-radio-label {
    @apply text-base leading-5;
  }
}

.pc-radio[data-theme='icing'] {
  @apply inline-flex items-center p-0.5;

  .pc-radio-field {
    @apply flex-shrink-0 mr-2 rounded-full border border-interactive-form cursor-pointer appearance-none duration-200 transition-all;
    width: 22px;
    height: 22px;

    &:focus {
      @apply shadow-reactive-focus-01;
    }

    &:not(:disabled):not(:checked) {
      &:hover {
        @mixin useInputHover;
      }

      &:active {
        @mixin useInputActive;
      }
    }

    &:not(:disabled):checked {
      @apply bg-interactive-brand border-interactive-brand;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");

      &:hover {
        background-color: var(--dt-pc-color-iteractive-brand-mix-hover-01);
        border-color: var(--dt-pc-color-iteractive-brand-mix-hover-01);
      }

      &:active {
        background-color: var(--dt-pc-color-iteractive-brand-mix-active-01);
        border-color: var(--dt-pc-color-iteractive-brand-mix-active-01);
      }
    }

    &:disabled {
      @apply cursor-not-allowed;
      @apply bg-disabled-01 text-content-disabled border-color-default;
    }

    &:disabled:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23929FAA' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
    }
  }
  &.pc-radio--error {
    .pc-radio-field {
      @apply border-support-error;

      &:not(:disabled):checked {
        @apply bg-support-error border-support-error;

        &:hover,
        &:active {
          @apply bg-support-error border-support-error;
        }
      }
    }
  }
}
</style>
