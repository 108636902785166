var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewport: "",
        viewBox: "-6 -8 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.0056 1.00584C10.818 0.817554 10.5631 0.71172 10.2973 0.71172C10.0314 0.71172 9.77656 0.817554 9.58893 1.00584L5.7131 4.88001L1.8356 1.00251C1.64796 0.814221 1.39308 0.708387 1.12726 0.708387C0.861444 0.708387 0.606565 0.814221 0.418929 1.00251C0.230641 1.19014 0.124807 1.44502 0.124807 1.71084C0.124807 1.97666 0.230641 2.23154 0.418929 2.41918L5.00226 7.00251C5.1899 7.1908 5.44478 7.29663 5.7106 7.29663C5.97641 7.29663 6.23129 7.1908 6.41893 7.00251L11.0023 2.41918C11.3931 2.02967 11.3946 1.39718 11.0056 1.00584Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }