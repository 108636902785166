<template>
  <div
    :data-theme="themeName"
    class="pc-back-to-top"
    :class="[{ 'pc-back-to-top--expanded': isExpanded }]"
  >
    <button
      class="pc-back-to-top-button"
      :class="[
        buttonClass,
        { 'pc-back-to-top-button--visible': isVisible },
        isMobile ? 'inline-flex right-0' : 'flex left-full'
      ]"
      @click="scrollToTop"
      @mouseenter="isExpanded = true"
      @mouseleave="isExpanded = false"
    >
      <pc-icon class="pc-back-to-top-icon" name="arrowTop" />
      <span
        v-if="text"
        class="pc-back-to-top-text"
        :class="{ 'max-w-0': !isExpanded }"
      >
        {{ text }}
      </span>
    </button>
  </div>
</template>

<script>
import PcIcon from '../Icon'
import breakpoint from '../../mixins/breakpoint'
import theme from '../../mixins/theme'

export default {
  components: {
    PcIcon
  },
  mixins: [breakpoint, theme],
  props: {
    text: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isExpanded: false,
    isVisible: false,
    lastScrollTop: 0
  }),
  async mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let st = window.pageYOffset || document.documentElement.scrollTop
      if (st > this.lastScrollTop) {
        this.isVisible = false
      } else {
        this.isVisible = st >= 400
      }
      this.lastScrollTop = st <= 0 ? 0 : st
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';

.pc-back-to-top {
  @apply sticky text-right;
  bottom: 1.5rem;
}

.pc-back-to-top-icon {
  @apply w-6 h-6;
}

.pc-back-to-top[data-theme='default'] {
  .pc-back-to-top-button {
    @apply h-10 p-2 -ml-8 transition-all duration-300 ease-in-out transform items-center
    bg-orange-100 text-white rounded-full relative scale-0;

    &--visible {
      @apply scale-100;
    }
  }
  .pc-back-to-top-text {
    @apply font-bold overflow-hidden opacity-0 hidden;
    @screen lg {
      @apply block;
    }
  }

  &.pc-back-to-top--expanded {
    .pc-back-to-top-button {
      @screen lg {
        @apply px-6;
      }
    }
    .pc-back-to-top-text {
      @apply ml-2 opacity-100 max-w-xs overflow-visible;
    }
  }
}

.pc-back-to-top[data-theme='icing'] {
  .pc-back-to-top-button {
    @apply h-10 p-2 -ml-8 transition-all duration-200 ease-in-out transform items-center
    bg-interactive-brand text-content-interactive-light
    shadow-elevation-03 rounded-full relative scale-0;

    &:hover {
      background-color: var(--dt-pc-color-iteractive-brand-mix-hover-01);
    }
    &:active {
      background-color: var(--dt-pc-color-iteractive-brand-mix-active-01);
    }
    &:focus {
      @apply outline-none shadow-outline-focus;
    }

    &--visible {
      @apply scale-100;
    }
  }
  .pc-back-to-top-text {
    @apply text-content-interactive-light overflow-hidden opacity-0 hidden;
    @mixin interactive-s;

    @screen laptop {
      @apply block;
    }
  }
  &.pc-back-to-top--expanded {
    .pc-back-to-top-button {
      @screen laptop {
        @apply px-6;
      }
    }
    .pc-back-to-top-text {
      @apply ml-2 opacity-100 max-w-xs overflow-visible;
    }
  }
}
</style>
