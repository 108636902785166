module.exports = {
  BREAKPOINTS: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1200,
    mobile: 0,
    tablet: 768,
    laptop: 1024,
    desktop: 1200
  },
  theme: 'icing'
}
