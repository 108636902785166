export const ButtonSizesList = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  small16: 'small16',
  xsmall: 'xsmall'
}

export const ButtonTypesList = {
  primary: 'primary',
  secondary: 'secondary',
  outline: 'outline',
  ghost: 'ghost'
}
