var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showComponent
    ? _c(
        "error-boundary",
        _vm._g(
          _vm._b(
            {
              attrs: { stopPropagation: false },
              on: { "on-error": _vm.handleComponentError },
            },
            "error-boundary",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }