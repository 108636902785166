<template>
  <div
    :data-theme="themeName"
    class="pc-progress"
    :class="[{ 'pc-progress-brand': brand }]"
  >
    <div v-if="label" class="pc-progress-label">{{ label }}</div>
    <div class="pc-progress-track">
      <div class="pc-progress-bar" :style="{ width: `${percent}%` }"></div>
    </div>
  </div>
</template>

<script>
import theme from '../../mixins/theme'

export default {
  name: 'PcProgress',
  mixins: [theme],
  props: {
    percent: {
      type: Number,
      required: true
    },
    brand: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/form-input.pcss';

.pc-progress[data-theme='default'] {
  .pc-progress-track {
    @apply rounded-sm bg-grey-30 h-2;
  }

  .pc-progress-bar {
    @apply bg-green-100 rounded-sm h-full;
  }
}

.pc-progress[data-theme='icing'] {
  .pc-progress-track {
    @apply bg-interactive-secondary h-2;
    border-radius: 3px;
  }

  .pc-progress-bar {
    @apply bg-interactive-primary h-full;
    border-radius: 3px;
  }

  .pc-progress-label {
    @apply mb-3.5;
    @mixin useInputLabel;
  }

  &.pc-progress-brand .pc-progress-bar {
    @apply bg-interactive-brand;
  }
}
</style>
