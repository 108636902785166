<template>
  <label
    :data-theme="themeName"
    class="pc-chip"
    :class="[
      `pc-chip--${type}`,
      { 'pc-chip--disabled': disabled },
      { 'pc-chip--checked': isChecked }
    ]"
    tabindex="0"
  >
    <input
      :checked="isChecked"
      :disabled="disabled"
      :value="value"
      class="opacity-0 w-0 h-0"
      type="checkbox"
      @change="onCheckboxChange"
    />
    <pc-icon
      v-if="hasCheckIcon && isChecked"
      class="mr-2 inline-block"
      name="check"
    />
    <slot />
  </label>
</template>

<script>
import checkbox from '../../mixins/checkbox'
import theme from '../../mixins/theme'
import { CHIPS_TYPES } from './constants'
import PcIcon from '../Icon/Icon.vue'

export default {
  name: 'PcChip',
  components: {
    PcIcon
  },
  mixins: [checkbox, theme],
  props: {
    type: {
      type: String,
      default: CHIPS_TYPES.default,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(CHIPS_TYPES, value)
      }
    },
    hasCheckIcon: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';

.pc-chip[data-theme='default'] {
  @apply px-3 inline-flex py-1 rounded-full cursor-pointer font-bold text-sm border;

  &:focus {
    @apply outline-none;
  }

  &.pc-chip--default:not(.pc-chip--disabled),
  &.pc-chip--ghost:not(.pc-chip--disabled),
  &.pc-chip--outline:not(.pc-chip--disabled) {
    @apply bg-blue-10 border-blue-10 text-blue-100;

    &:hover {
      @apply bg-blue-20 border-blue-20;
    }

    &:active {
      @apply bg-grey-40 border-grey-40;
    }

    &:focus {
      @apply shadow-outline-button;
    }
  }

  &.pc-chip--checked:not(.pc-chip--disabled) {
    @apply bg-blue-100 border-blue-100 text-white;

    &:hover {
      @apply bg-blue-200 border-blue-200;
    }

    &:active {
      @apply border-blue-300 bg-blue-300;
    }

    &:focus {
      @apply shadow-outline-button;
    }
  }

  &.pc-chip--disabled {
    @apply bg-grey-30 border-grey-30 text-grey-45;
  }
}

.pc-chip[data-theme='icing'] {
  @apply px-3 py-0.5 h-8 items-center inline-flex
    duration-200 transition-colors
    text-content-interactive-secondary border border-transparent rounded-full cursor-pointer;
  @mixin interactive-xs;

  &:focus {
    @apply outline-none shadow-outline-focus;
  }

  &.pc-chip--default:not(.pc-chip--disabled) {
    @apply bg-interactive-secondary;

    &:hover {
      background-color: var(--dt-pc-color-iteractive-secondary-mix-hover-01);
    }

    &:active {
      background-color: var(--dt-pc-color-iteractive-secondary-mix-active-01);
    }
  }

  &.pc-chip--outline:not(.pc-chip--disabled) {
    @apply border-strong;
  }

  &.pc-chip--outline:not(.pc-chip--disabled),
  &.pc-chip--ghost:not(.pc-chip--disabled) {
    &:hover {
      @apply bg-hover-01;
    }
    &:active {
      @apply bg-active-01;
    }
  }

  &.pc-chip--checked:not(.pc-chip--disabled) {
    @apply transition duration-200 bg-interactive-primary text-content-interactive-primary;

    &:hover {
      background-color: var(--dt-pc-color-iteractive-primary-mix-hover-02);
    }

    &:active {
      background-color: var(--dt-pc-color-iteractive-primary-mix-active-02);
    }
  }

  &.pc-chip--disabled {
    @apply bg-disabled-01 text-content-disabled cursor-not-allowed;
  }
}
</style>
