var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pc-collapse",
      class: [{ "pc-collapse--divider": _vm.divider }],
      attrs: { "data-theme": _vm.themeName },
    },
    [
      _c(
        "div",
        {
          staticClass: "pc-collapse-header",
          class: _vm.disabledFrom + ":hidden",
          attrs: { tabindex: "0", "data-test": "card-header" },
          on: { click: _vm.toggle },
        },
        [
          _c(
            "div",
            { staticClass: "flex justify-between items-center" },
            [
              _c("div", { staticClass: "flex pr-4" }, [
                _vm.$slots.icon
                  ? _c(
                      "div",
                      { staticClass: "flex items-center justify-center mr-4" },
                      [_vm._t("icon")],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("h6", { staticClass: "pc-collapse-header-text" }, [
                  _vm._v(_vm._s(_vm.headerText)),
                ]),
              ]),
              _vm._v(" "),
              _c("pc-icon", {
                staticClass: "pc-collapse-icon transition duration-300 linear",
                class: [
                  { "transform rotate-180": _vm.isOpened },
                  _vm.arrowColor,
                ],
                attrs: { name: "chevronDown" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.secondaryText
            ? _c("p", { staticClass: "pc-collapse-secondary-text mt-1" }, [
                _vm._v("\n      " + _vm._s(_vm.secondaryText) + "\n    "),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("collapse-transition", [
        _vm.isOpened || !_vm.collapsible
          ? _c(
              "div",
              {
                staticClass: "pc-collapse-body",
                attrs: { "data-test": "card-body" },
              },
              [_vm._t("default")],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }