import { themeConfig } from '../helpers/themeResolver'

export default {
  data() {
    return {
      themeConfig
    }
  },
  computed: {
    themeName() {
      return themeConfig.current
    }
  }
}
