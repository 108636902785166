var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pc-notification space-x-4",
      class: ["pc-notification--" + _vm.type, _vm.title ? "" : "items-center"],
      attrs: { "data-theme": _vm.themeName },
    },
    [
      _vm.getIconName
        ? _c("icon", {
            staticClass: "pc-notification-icon w-6 h-6 flex-shrink-0",
            attrs: { name: _vm.getIconName },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "w-full" }, [
        _vm.title
          ? _c("p", { staticClass: "pc-notification-title" }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [_vm._t("default")], 2),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }