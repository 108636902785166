<template>
  <a @click.prevent="navigate" :href="to"><slot /></a>
</template>

<script>
import { navigateToUrl } from 'single-spa'

export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    navigate() {
      navigateToUrl(this.to)
    }
  }
}
</script>
