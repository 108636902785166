<template>
  <link-helper
    class="pc-tab"
    :class="[
      {
        'pc-tab-brand': brand,
        'pc-tab--disabled': disabled
      }
    ]"
    :href="href"
    active-class="active"
    v-on="$listeners"
  >
    <span class="relative"><slot></slot></span>
  </link-helper>
</template>

<script>
import { LinkHelper } from '../Link'

export default {
  name: 'PcTab',
  components: {
    LinkHelper
  },
  props: {
    brand: {
      type: Boolean,
      default: true
    },
    href: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';

.pc-tab {
  @mixin body-l;
  @apply flex relative p-3 font-bold text-content-subdued
  transition-colors duration-200 ease-in-out items-center;

  @screen laptop {
    @apply py-2 px-4;
  }
}

.pc-tab.active {
  @apply text-content-default;
}

.pc-tab:after {
  content: '';
  border-radius: 2px 2px 0 0;
  height: 4px;
  bottom: 0;
  @apply absolute left-0 w-full transition-opacity duration-200 ease-in-out opacity-0 bg-interactive-primary;
}

.pc-tab-brand:after {
  @apply bg-interactive-brand;
}

.pc-tab.active:after {
  @apply opacity-100;
}

.pc-tab:before {
  @apply absolute inset-0 bg-transparent transition-colors duration-200;
  content: '';
  border-radius: 2px;
}

.pc-tab:not(.pc-tab--disabled):hover:before {
  background-color: var(--dt-pc-color-backgound-default-mix-hover-01);
}

.pc-tab:not(.pc-tab--disabled):active:before {
  background-color: var(--dt-pc-color-backgound-default-mix-active-01);
}
.pc-tab--disabled {
  @apply text-content-disabled;
}
</style>
