<template>
  <span
    :data-theme="themeName"
    class="pc-tag"
    :class="[
      `pc-tag--${type}`,
      small ? `pc-tag--small` : `pc-tag--regular`,
      typeClasses
    ]"
  >
    <span
      v-if="$slots.icon"
      class="flex items-center justify-center overflow-hidden mr-2 w-4 h-4"
    >
      <slot name="icon"></slot>
    </span>
    <slot></slot>
  </span>
</template>

<script>
import { TAG_TYPES } from './constants'
import theme from '../../mixins/theme'

export default {
  name: 'PcTag',
  mixins: [theme],
  props: {
    type: {
      type: String,
      validator: (value) =>
        Object.prototype.hasOwnProperty.call(TAG_TYPES, value)
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    typeClasses() {
      return TAG_TYPES[this.type]
    }
  }
}
</script>

<style scoped>
@import '../../assets/themes/icing/css/mixins/typography.pcss';

.pc-tag[data-theme='default'] {
  @apply rounded-sm text-center inline-flex items-center py-1 px-2 h-6;

  &.pc-tag--small {
    @apply text-xs;
  }

  &.pc-tag--regular {
    @apply text-sm;
  }
}

.pc-tag[data-theme='icing'] {
  @apply text-center inline-flex items-center;

  &.pc-tag--small {
    @apply py-0 px-1 h-4 rounded-radius-a;
    @mixin caption;
  }

  &.pc-tag--regular {
    @apply py-0.5 px-1 h-6 rounded-radius-b;
    @mixin body-s;
  }
}
</style>
