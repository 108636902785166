var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "fill-current",
      attrs: {
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M17.8013 4.80762L12 10.6089L6.19875 4.80762L4.8075 6.19887L10.6088 12.0002L4.8075 17.8014L6.19875 19.1927L12 13.3914L17.8013 19.1927L19.1926 17.8014L13.3913 12.0002L19.1926 6.19887L17.8013 4.80762Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }