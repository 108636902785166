var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    container\n    box-border\n    mt-4\n    md:mt-8\n    flex\n    items-center\n    flex-col\n    space-y-8\n  ",
    },
    [
      _c("error-icon", { staticClass: "mt-4 md:w-4/6" }),
      _vm._v(" "),
      _vm._t("default", [
        _c("p", { staticClass: "font-bold text-2xl md:text-3xl text-center" }, [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.errorMessage)) + "\n    "),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }