var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.04375 13.18L10.3987 8.00004L5 2.82004L5.875 1.85754L11.4137 7.20379C11.6291 7.42164 11.7499 7.7156 11.7499 8.02192C11.7499 8.32823 11.6291 8.6222 11.4137 8.84004L5.945 14.125L5.04375 13.18Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }