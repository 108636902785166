<template>
  <div :data-theme="themeName" class="pc-rating flex space-x-1 items-center">
    <svg
      v-for="i in max"
      :key="i"
      :width="`${starSize}px`"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs v-if="isCurrentStar(i)">
        <linearGradient :id="id" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop :offset="getStarPercentage(i)" class="gradient-start" />
          <stop :offset="getStarPercentage(i)" class="gradient-end" />
        </linearGradient>
      </defs>
      <path
        :class="getStarClass(i)"
        d="M14.3594 17.0609L9.13124 14.3156C9.091 14.2936 9.04587 14.2821 8.99999 14.2821C8.95412 14.2821 8.90899 14.2936 8.86874 14.3156L3.64062 17.0609L4.62499 11.2422C4.6566 11.1651 4.6566 11.0786 4.62499 11.0015L0.337494 6.87811L6.17812 6.02499C6.22135 6.01883 6.26258 6.00281 6.29862 5.97815C6.33466 5.95349 6.36453 5.92087 6.38593 5.8828L8.99999 0.58905L11.6141 5.8828C11.6355 5.92087 11.6653 5.95349 11.7014 5.97815C11.7374 6.00281 11.7786 6.01883 11.8219 6.02499L17.6625 6.87811L13.4297 11.0015C13.4011 11.0343 13.3796 11.0725 13.3664 11.1139C13.3533 11.1553 13.3487 11.199 13.3531 11.2422L14.3594 17.0609ZM3.85937 8.02655L5.71874 9.8203C5.93973 10.0379 6.10486 10.3057 6.20008 10.6009C6.29529 10.8961 6.31778 11.2099 6.26562 11.5156L5.82812 14.064L8.11406 12.8609C8.38668 12.7154 8.69096 12.6393 8.99999 12.6393C9.30903 12.6393 9.61331 12.7154 9.88593 12.8609L12.1719 14.064L11.7344 11.5156C11.6822 11.2099 11.7047 10.8961 11.7999 10.6009C11.8951 10.3057 12.0603 10.0379 12.2812 9.8203L14.1406 8.02655L11.5812 7.65468C11.2656 7.61694 10.9643 7.50119 10.7046 7.31787C10.4449 7.13456 10.235 6.88945 10.0937 6.60468L8.99999 4.28593L7.90624 6.60468C7.7746 6.89201 7.57386 7.14228 7.32193 7.33313C7.07001 7.52399 6.77474 7.64949 6.46249 7.69843L3.85937 8.02655Z"
      />
      <path
        class="inner"
        :style="style(i)"
        d="M 16.6 6.2 Z M 9 12.6 L 5.2 14.9 L 6.2 10.6 L 2.9 7.7 L 7.3 7.3 L 9 3.3 L 10.7 7.3 L 15.1 7.7 L 11.8 10.6 L 12.8 14.9 L 9 12.6 Z"
      ></path>
    </svg>
  </div>
</template>

<script>
import id from '../../helpers/randomId'
import theme from '../../mixins/theme'
import { themeConfig } from '../../helpers/themeResolver'
const starSizes = {
  xs: 16,
  s: 20,
  m: 24,
  l: 32
}
const defaultProps = {
  size: themeConfig.isIcing ? starSizes.m : 18
}

export default {
  name: 'PcRating',
  mixins: [theme],
  props: {
    width: {
      type: Number,
      default: defaultProps.size
    },
    rating: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: 6
    },
    size: {
      type: String,
      validator(value) {
        return value
          ? true
          : Object.prototype.hasOwnProperty.call(starSizes, value)
      }
    }
  },
  computed: {
    id,
    starSize() {
      return starSizes[this.size] || this.width
    }
  },
  methods: {
    isCurrentStar(index) {
      const diff = this.rating - index + 1
      return diff >= 0 && diff < 1
    },
    getStarPercentage(index) {
      return parseInt((this.rating - (index - 1)) * 100) + '%'
    },
    getStarClass(index) {
      const classNames = ['star']
      this.rating > index - 1 && classNames.push('filled')
      index === parseInt(this.rating) + 1 && classNames.push('gradient')
      return classNames.join(' ')
    },
    style(i) {
      return (
        this.getStarClass(i).indexOf('gradient') > -1 &&
        `fill: url(#${this.id})`
      )
    }
  }
}
</script>

<style scoped>
.pc-rating[data-theme='default'] {
  .star {
    fill: #929faa;
  }

  .star + .inner {
    fill: #fff;
  }

  .star.filled,
  .star.filled + .inner {
    fill: #f36d00;
  }

  .gradient-start {
    stop-color: #f36d00;
    stop-opacity: 1;
  }

  .gradient-end {
    stop-color: #fff;
    stop-opacity: 1;
  }
}
.pc-rating[data-theme='icing'] {
  .star {
    fill: var(--dt-color-content-brand);
  }

  .star + .inner {
    fill: var(--dt-color-white);
  }

  .star.filled,
  .star.filled + .inner {
    fill: var(--dt-color-content-brand);
  }

  .gradient-start {
    stop-color: var(--dt-color-content-brand);
    stop-opacity: 1;
  }

  .gradient-end {
    stop-color: var(--dt-color-white);
    stop-opacity: 1;
  }
}
</style>
