var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    container\n    fixed\n    bottom-0\n    left-0\n    right-0\n    m-auto\n    mb-3\n    z-10\n    flex flex-col\n    justify-end\n    h-0\n  ",
      attrs: { "data-cy": "pc-toast-container" },
    },
    [
      _c(
        "transition-group",
        {
          staticClass: "pc-toast-wrapper",
          attrs: {
            tag: "div",
            "enter-active-class": "transition transform duration-300 ease-out",
            "enter-class": "opacity-0",
            "enter-to-class": "opacity-100",
            "leave-active-class": "transition transform duration-300 ease-out",
            "leave-class": "opacity-100",
            "leave-to-class": "opacity-0",
          },
        },
        _vm._l(_vm.toastsList, function (toast) {
          return _c(
            "div",
            {
              key: toast.id,
              staticClass: "pc-toast",
              class: [
                "pc-toast--" + toast.type,
                { "pc-toast--compact": toast.compact },
              ],
              attrs: {
                "data-theme": _vm.themeName,
                "data-cy": "pc-toast pc-toast-" + toast.type,
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex", class: { "flex-grow": toast.compact } },
                [
                  !toast.compact && _vm.getIconName(toast.iconName, toast.type)
                    ? _c(
                        "div",
                        { staticClass: "pc-toast-icon" },
                        [
                          _c("pc-icon", {
                            staticClass: "w-6 h-6",
                            attrs: {
                              name: _vm.getIconName(toast.iconName, toast.type),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t(
                    "message",
                    [
                      _c("p", {
                        domProps: { innerHTML: _vm._s(toast.message) },
                      }),
                    ],
                    { item: toast }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm._t(
                "actions",
                [
                  toast.closeButton
                    ? _c(
                        "div",
                        {
                          staticClass: "text-right",
                          class: { "w-full mt-3": !toast.compact },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "pc-toast-close",
                              on: {
                                click: function ($event) {
                                  return _vm.removeToast(toast.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(toast.closeButton) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                {
                  item: toast,
                  close: function () {
                    return _vm.removeToast(toast.id)
                  },
                }
              ),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }