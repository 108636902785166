var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _vm._t("activator", null, { openSidebar: _vm.open }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fixed z-100" },
        [
          _c(
            "transition",
            {
              attrs: {
                "enter-active-class": "transition duration-300 ease-out",
                "enter-class": "transform opacity-0",
                "enter-to-class": "transform opacity-100",
                "leave-active-class": "transition duration-300 ease-out",
                "leave-class": "transform opacity-100",
                "leave-to-class": "transform opacity-0",
              },
            },
            [
              _vm.isVisible
                ? _c(
                    "div",
                    {
                      staticClass: "fixed inset-0",
                      attrs: { "data-test": "sidebar-drawer" },
                      on: { click: _vm.close },
                    },
                    [
                      _c("div", {
                        staticClass: "absolute inset-0 bg-grey-100 opacity-50",
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "sidebar-content bg-container-default shadow-elevation-03 fixed top-0 h-full overflow-auto ease-in-out transition-all duration-300 transform flex flex-col",
              class: _vm.sideBarClasses,
              attrs: { "data-test": "sidebar" },
            },
            [_vm._t("default", null, { closeSidebar: _vm.close })],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }