<template>
  <div class="sidebar">
    <slot name="activator" :open-sidebar="open" />
    <div class="fixed z-100">
      <transition
        enter-active-class="transition duration-300 ease-out"
        enter-class="transform opacity-0"
        enter-to-class="transform opacity-100"
        leave-active-class="transition duration-300 ease-out"
        leave-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div
          v-if="isVisible"
          data-test="sidebar-drawer"
          class="fixed inset-0"
          @click="close"
        >
          <div class="absolute inset-0 bg-grey-100 opacity-50" />
        </div>
      </transition>
      <div
        data-test="sidebar"
        class="sidebar-content bg-container-default shadow-elevation-03 fixed top-0 h-full overflow-auto ease-in-out transition-all duration-300 transform flex flex-col"
        :class="sideBarClasses"
      >
        <slot name="default" :close-sidebar="close" />
      </div>
    </div>
  </div>
</template>

<script>
const OVERFLOW_HIDDEN_CLASS = 'overflow-hidden'

export default {
  name: 'PcSidebar',
  props: {
    position: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'right'].includes(value)
      }
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    sideBarClasses() {
      return [
        this.isVisible ? 'sidebar--opened' : 'sidebar--closed',
        this.position === 'left'
          ? 'sidebar-left left-0'
          : 'sidebar-right right-0'
      ]
    }
  },
  beforeDestroy() {
    this.close()
  },
  methods: {
    open() {
      this.isVisible = true
      this.$nextTick(() => {
        this.disableScroll()
      })
      window.addEventListener('single-spa:before-routing-event', this.close)
    },
    close() {
      this.isVisible = false
      setTimeout(() => {
        this.enableScroll()
      }, 300)
      window.removeEventListener('single-spa:before-routing-event', this.close)
    },
    enableScroll() {
      document.body.classList.remove(OVERFLOW_HIDDEN_CLASS)
    },
    disableScroll() {
      document.body.classList.add(OVERFLOW_HIDDEN_CLASS)
    }
  }
}
</script>

<style scoped>
.sidebar-content {
  width: 90%;
  max-width: 300px;

  @screen tablet {
    max-width: 360px;
  }
}

.sidebar--opened {
  transform: translateX(0);
}

.sidebar-left.sidebar--closed {
  transform: translateX(-100%);
}

.sidebar-right.sidebar--closed {
  transform: translateX(100%);
}
</style>
