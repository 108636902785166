var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("g", { attrs: { "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d: "M3.513 3.03C5.996.778 9.765.72 12.316 2.896l-.943.943C9.442 2.26 6.675 2.233 4.712 3.77 2.748 5.308 2.112 8 3.18 10.255c1.069 2.253 3.556 3.465 5.989 2.918 2.433-.547 4.162-2.707 4.163-5.201-.001-.414-.05-.827-.147-1.23l1.063-1.069c1.155 3.147-.208 6.662-3.181 8.21-2.974 1.547-6.634.646-8.55-2.105C.605 9.026 1.03 5.28 3.514 3.03zm10.274.283c.23.061.41.241.472.471.061.23-.004.476-.173.645l-6.57 6.667c-.26.26-.682.26-.943 0L4.21 8.733c-.125-.125-.196-.294-.196-.471 0-.142.045-.279.128-.392l.068-.08c.125-.125.295-.196.472-.196s.346.07.471.196l1.892 1.891 6.098-6.196c.168-.168.414-.234.644-.172z",
            transform: "translate(-492 -434) translate(484 430) translate(8 4)",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }