var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pc-dropdown", attrs: { "data-theme": _vm.themeName } },
    [
      _vm.label
        ? _c("div", { staticClass: "pc-dropdown-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pc-dropdown-inner",
          class: { disabled: _vm.disabled },
          attrs: { tabindex: _vm.getTabindexValue },
          on: {
            click: _vm.toggleSelect,
            keyup: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSelectKeyup($event)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                ) {
                  return null
                }
                return _vm.handleSelectKeyup($event)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                ) {
                  return null
                }
                return _vm.onArrowDown($event)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                ) {
                  return null
                }
                return _vm.onArrowUp($event)
              },
            ],
            focusout: function ($event) {
              _vm.isOpen = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "pc-dropdown-picker",
              class: {
                invalid: !_vm.isValid,
                disabled: _vm.disabled,
              },
            },
            [
              _vm.selectedValue[_vm.itemText]
                ? _c("p", { staticClass: "pc-dropdown-value" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.selectedValue[_vm.itemText]) +
                        "\n      "
                    ),
                  ])
                : _c("p", { staticClass: "pc-dropdown-placeholder" }, [
                    _vm._v(_vm._s(_vm.placeholder)),
                  ]),
              _vm._v(" "),
              _c("pc-icon", {
                staticClass: "transition duration-200 linear w-5 h-5",
                class: { "transform rotate-180": _vm.isOpen },
                attrs: { name: _vm.iconName },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            _vm.positionConfig.transition,
            { tag: "component", attrs: { appear: "" } },
            [
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOpen,
                      expression: "isOpen",
                    },
                  ],
                  ref: "dropdown",
                  staticClass: "pc-dropdown-list scrollbar",
                  class: _vm.positionConfig.dropdown,
                  staticStyle: { "max-height": "15rem" },
                },
                _vm._l(_vm.items, function (item, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      staticClass: "pc-dropdown-list-item",
                      class: {
                        selected: item[_vm.itemValue] === _vm.value,
                        "bg-grey-10": i === _vm.arrowCounter,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectItem(item)
                        },
                        mouseenter: function ($event) {
                          _vm.arrowCounter = -1
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(item[_vm.itemText]) +
                          "\n        "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.validationMessage
        ? _c(
            "p",
            { staticClass: "pc-dropdown-error-message" },
            [
              _c("pc-icon", {
                staticClass: "mr-1 w-5 h-5",
                attrs: { name: "error" },
              }),
              _vm._v("\n    " + _vm._s(_vm.validationMessage) + "\n  "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAvailableRecursive
        ? _c("pc-select", {
            staticClass: "mt-3",
            attrs: {
              items: _vm.getSubitems,
              placeholder: _vm.placeholder,
              "is-recursive": "",
            },
            on: { bus: _vm.bus },
            model: {
              value: _vm.subitemValue,
              callback: function ($$v) {
                _vm.subitemValue = $$v
              },
              expression: "subitemValue",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }