var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pc-radio",
      class: { "pc-radio--error": !_vm.isValid },
      attrs: { "data-theme": _vm.themeName },
    },
    [
      _c("input", {
        staticClass: "pc-radio-field",
        class: { checked: _vm.isChecked },
        attrs: { id: _vm.id, disabled: _vm.disabled, type: "radio" },
        domProps: { value: _vm.value, checked: _vm.isChecked },
        on: {
          change: function ($event) {
            return _vm.$emit("change", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "pc-radio-label", attrs: { for: _vm.id } },
        [
          _vm._t("default", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } }),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }