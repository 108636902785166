function getTitle(vm) {
  const { title } = vm.$options
  if (title) {
    return title.call(vm)
  }
}

export default {
  data: () => ({
    pageTitle: ''
  }),
  created() {
    const title = getTitle(this)
    if (title) {
      document.title = this.pageTitle = title
    }

    new MutationObserver(() => {
      this.pageTitle = document.title
    }).observe(document.querySelector('title'), { childList: true })
  }
}
