var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "link-helper",
    _vm._g(
      {
        staticClass: "pc-tab",
        class: [
          {
            "pc-tab-brand": _vm.brand,
            "pc-tab--disabled": _vm.disabled,
          },
        ],
        attrs: { href: _vm.href, "active-class": "active" },
      },
      _vm.$listeners
    ),
    [_c("span", { staticClass: "relative" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }