var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pc-back-to-top",
      class: [{ "pc-back-to-top--expanded": _vm.isExpanded }],
      attrs: { "data-theme": _vm.themeName },
    },
    [
      _c(
        "button",
        {
          staticClass: "pc-back-to-top-button",
          class: [
            _vm.buttonClass,
            { "pc-back-to-top-button--visible": _vm.isVisible },
            _vm.isMobile ? "inline-flex right-0" : "flex left-full",
          ],
          on: {
            click: _vm.scrollToTop,
            mouseenter: function ($event) {
              _vm.isExpanded = true
            },
            mouseleave: function ($event) {
              _vm.isExpanded = false
            },
          },
        },
        [
          _c("pc-icon", {
            staticClass: "pc-back-to-top-icon",
            attrs: { name: "arrowTop" },
          }),
          _vm._v(" "),
          _vm.text
            ? _c(
                "span",
                {
                  staticClass: "pc-back-to-top-text",
                  class: { "max-w-0": !_vm.isExpanded },
                },
                [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }