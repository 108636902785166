export const TOAST_TYPES = {
  default: {
    iconName: null
  },
  neutral: {
    iconName: null
  },
  success: {
    iconName: 'checkFilled'
  },
  positive: {
    iconName: 'checkFilled'
  },
  info: {
    iconName: 'info'
  },
  warning: {
    iconName: 'warning'
  },
  error: {
    iconName: 'error'
  }
}

export const DEFAULT_CONFIG = {
  type: 'default',
  message: '',
  iconName: '',
  compact: false,
  autoclose: true,
  autocloseTimeout: 5000,
  closeButton: 'Close'
}
